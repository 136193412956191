// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD3cBJdQaMOR2IAkgctD1MkvXlY2kXoW2Q",
  authDomain: "pevv3-13273.firebaseapp.com",
  projectId: "pevv3-13273",
  storageBucket: "pevv3-13273.appspot.com",
  messagingSenderId: "703176476897",
  appId: "1:703176476897:web:a8dd260a58da8b050f8fa0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

const db = getFirestore(app);

export { db };



