import { useEffect, useState, useRef } from 'react';
import styles from './Home.module.css'
import Cela from '../../components/Cela'
import { Table } from 'reactstrap';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import logo from '../../assets/img/logo.png'
import { FaSearch } from "react-icons/fa";
import userDef from '../../assets/img/User-Default3.jpg'
import { FaEye } from "react-icons/fa";
import Loading from '../../components/Loading';
import { Skeleton, styled } from '@mui/material';


const ShinySkeleton = styled(Skeleton)(({ theme }) => ({
  background: 'linear-gradient(90deg, rgba(255,255,255,0.05) 25%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.05) 75%)',
  backgroundSize: '200% 100%',
  animation: 'shine 1.5s infinite',
  '@keyframes shine': {
    '0%': {
      backgroundPosition: '-200% 0',
    },
    '100%': {
      backgroundPosition: '200% 0',
    },
  },
}));

const Home = ({ userVerificacao, galerias }) => {
  const [pagina, setPagina] = useState(1)
  const [pessoasDomiciliar, setPessoasDomiciliar] = useState('')
  const [pessoasHospital, setPessoasHospital] = useState('')
  const [pessoasPernoite, setPessoasPernoite] = useState('')
  const [modalBuscaAberto, setModalBuscaAberto] = useState(false);
  const [termoBusca, setTermoBusca] = useState('');
  const [resultadosBusca, setResultadosBusca] = useState([]);
  
  const [ativaBotao, setAtivaBotao] = useState(1)
  const [usuario] = useState(userVerificacao)
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false)
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const [nomeBuscado, setNomeBuscado] = useState('')

  const inputBuscaRef = useRef(null);

  useEffect(() => {
    if (modalBuscaAberto) {
      const timer = setTimeout(() => {
        inputBuscaRef.current.focus();
      }, 500);

      // Limpar o timer quando o componente for desmontado ou se modalBuscaAberto mudar
      return () => clearTimeout(timer);
    }
  }, [modalBuscaAberto]);


  const scrollToCoordinates = (cela) => {
    const targetElement = document.getElementById(cela);

    if (targetElement) {
      // Rolar até o elemento
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleCliqueBuscaCela = (cela, nome) => {
    setNomeBuscado(nome)
    const primeiraLetra = cela.charAt(0);
    const segundaletra = cela.charAt(1);




    if (primeiraLetra == 'A') {

      paginaGaleriaA()
     
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);


    }
   
    if (primeiraLetra == 'B') {
      paginaGaleriaB()
      
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'C') {
      paginaGaleriaC()
      
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'D') {
      paginaGaleriaD()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'E') {
      paginaGaleriaE()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'I') {
      paginaGaleriaF()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
   

    if (primeiraLetra == 'T') {
      paginaGaleriaTR()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }

    if (segundaletra == 'O') {
      paginaForaUP()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
    if (primeiraLetra == 'P') {
      paginaForaUP()
      setModalBuscaAberto(!modalBuscaAberto)
      setTimeout(() => {
        scrollToCoordinates(cela)
      }, 500);
    }
  };


  const toggleModalBusca = () => {
    setTermoBusca('')
    setModalBuscaAberto(!modalBuscaAberto);
    setResultadosBusca([])
  };


  useEffect(() => {
    if (termoBusca === "SEM INFOPEN") {
      // Caso o termo de busca seja "SEM INFOPEN", filtre os registros onde infopen é 0
      setResultadosBusca(galerias.filter(item => item.infopen == 0));
      return; // Retorne para evitar a execução do restante do código
    }

    if (termoBusca.length < 3 || !galerias) {
      setResultadosBusca([]);
      return; // Retorne para evitar a execução do restante do código
    }

    // O restante do código permanece inalterado
    const termoNumerico = parseInt(termoBusca);

    const resultados = galerias.filter(item => {
      const infopenStr = item.infopen; // Converte infopen para string

      return (
        (typeof item.nome === 'string' && item.nome.includes(termoBusca)) ||
        (typeof item.selectDate === 'string' && item.selectDate.includes(termoBusca)) ||
        (typeof item.cela === 'string' && item.cela.includes(termoBusca)) ||
        (typeof item.tipo === 'string' && item.tipo.includes(termoBusca)) ||
        (typeof item.artigo === 'string' && item.artigo.includes(termoBusca)) ||
        (typeof item.selecao === 'string' && item.selecao.includes(termoBusca)) ||
        (typeof item.infopen === 'string' && infopenStr.includes(termoBusca)) || // Busca por string
        (typeof item.infopen === 'number' && item.infopen === termoNumerico) // Busca por número
      );
    });

    // Atualizar os resultados da busca
    setResultadosBusca(resultados);
  }, [galerias, termoBusca]);


  // criando componente que vai até o topo do quarante com o botão




  // criação das variáveis MAP GALERIA A
  const [pessoasSearchMapA01, setPessoasSearchMapA01] = useState('')
  const [pessoasSearchMapA02, setPessoasSearchMapA02] = useState('')
  const [pessoasSearchMapA03, setPessoasSearchMapA03] = useState('')
  const [pessoasSearchMapA04, setPessoasSearchMapA04] = useState('')
  const [pessoasSearchMapA05, setPessoasSearchMapA05] = useState('')
  const [pessoasSearchMapA06, setPessoasSearchMapA06] = useState('')
  const [pessoasSearchMapA07, setPessoasSearchMapA07] = useState('')
  const [pessoasSearchMapA08, setPessoasSearchMapA08] = useState('')
  const [pessoasSearchMapA09, setPessoasSearchMapA09] = useState('')
  const [pessoasSearchMapA10, setPessoasSearchMapA10] = useState('')
  const [pessoasSearchMapA11, setPessoasSearchMapA11] = useState('')
  const [pessoasSearchMapA12, setPessoasSearchMapA12] = useState('')
  


  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB00, setPessoasSearchMapB00] = useState('')
  const [pessoasSearchMapB01, setPessoasSearchMapB01] = useState('')
  const [pessoasSearchMapB02, setPessoasSearchMapB02] = useState('')
  const [pessoasSearchMapB03, setPessoasSearchMapB03] = useState('')
  const [pessoasSearchMapB04, setPessoasSearchMapB04] = useState('')
  const [pessoasSearchMapB05, setPessoasSearchMapB05] = useState('')
  const [pessoasSearchMapB06, setPessoasSearchMapB06] = useState('')
  const [pessoasSearchMapB07, setPessoasSearchMapB07] = useState('')
  const [pessoasSearchMapB08, setPessoasSearchMapB08] = useState('')
  const [pessoasSearchMapB09, setPessoasSearchMapB09] = useState('')
  const [pessoasSearchMapB10, setPessoasSearchMapB10] = useState('')
  const [pessoasSearchMapB11, setPessoasSearchMapB11] = useState('')
  const [pessoasSearchMapB12, setPessoasSearchMapB12] = useState('')
  const [pessoasSearchMapB13, setPessoasSearchMapB13] = useState('')
  const [pessoasSearchMapB14, setPessoasSearchMapB14] = useState('')
  const [pessoasSearchMapB15, setPessoasSearchMapB15] = useState('')
 
  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC00, setPessoasSearchMapC00] = useState('')
  const [pessoasSearchMapC01, setPessoasSearchMapC01] = useState('')
  const [pessoasSearchMapC02, setPessoasSearchMapC02] = useState('')
  const [pessoasSearchMapC03, setPessoasSearchMapC03] = useState('')
  const [pessoasSearchMapC04, setPessoasSearchMapC04] = useState('')
  const [pessoasSearchMapC05, setPessoasSearchMapC05] = useState('')
  const [pessoasSearchMapC06, setPessoasSearchMapC06] = useState('')
  const [pessoasSearchMapC07, setPessoasSearchMapC07] = useState('')
  const [pessoasSearchMapC08, setPessoasSearchMapC08] = useState('')
  const [pessoasSearchMapC09, setPessoasSearchMapC09] = useState('')
  const [pessoasSearchMapC10, setPessoasSearchMapC10] = useState('')
  const [pessoasSearchMapC11, setPessoasSearchMapC11] = useState('')
  const [pessoasSearchMapC12, setPessoasSearchMapC12] = useState('')
  const [pessoasSearchMapC13, setPessoasSearchMapC13] = useState('')
  const [pessoasSearchMapC14, setPessoasSearchMapC14] = useState('')
  const [pessoasSearchMapC15, setPessoasSearchMapC15] = useState('')
  const [pessoasSearchMapC16, setPessoasSearchMapC16] = useState('')
  const [pessoasSearchMapC17, setPessoasSearchMapC17] = useState('')



  // CRIAÇÃO DAS VARIAVEIS GALERIA D (DD + DE)
  const [pessoasSearchMapD00, setPessoasSearchMapD00] = useState('')
  const [pessoasSearchMapD01, setPessoasSearchMapD01] = useState('')
  const [pessoasSearchMapD02, setPessoasSearchMapD02] = useState('')
  const [pessoasSearchMapD03, setPessoasSearchMapD03] = useState('')
  const [pessoasSearchMapD04, setPessoasSearchMapD04] = useState('')
  const [pessoasSearchMapD05, setPessoasSearchMapD05] = useState('')
  const [pessoasSearchMapD06, setPessoasSearchMapD06] = useState('')
  const [pessoasSearchMapD07, setPessoasSearchMapD07] = useState('')
  const [pessoasSearchMapD08, setPessoasSearchMapD08] = useState('')
  const [pessoasSearchMapD09, setPessoasSearchMapD09] = useState('')
  const [pessoasSearchMapD10, setPessoasSearchMapD10] = useState('')
  const [pessoasSearchMapD11, setPessoasSearchMapD11] = useState('')
  const [pessoasSearchMapD12, setPessoasSearchMapD12] = useState('')
  const [pessoasSearchMapD13, setPessoasSearchMapD13] = useState('')
  const [pessoasSearchMapD14, setPessoasSearchMapD14] = useState('')
  const [pessoasSearchMapD15, setPessoasSearchMapD15] = useState('')
  const [pessoasSearchMapD16, setPessoasSearchMapD16] = useState('')
  const [pessoasSearchMapD17, setPessoasSearchMapD17] = useState('')
  


  // GALERIA E

  const [pessoasSearchMapE00, setPessoasSearchMapE00] = useState('')
  const [pessoasSearchMapE01, setPessoasSearchMapE01] = useState('')
  const [pessoasSearchMapE02, setPessoasSearchMapE02] = useState('')
  const [pessoasSearchMapE03, setPessoasSearchMapE03] = useState('')
  const [pessoasSearchMapE04, setPessoasSearchMapE04] = useState('')
  const [pessoasSearchMapE05, setPessoasSearchMapE05] = useState('')
  const [pessoasSearchMapE06, setPessoasSearchMapE06] = useState('')
  const [pessoasSearchMapE07, setPessoasSearchMapE07] = useState('')
  const [pessoasSearchMapE08, setPessoasSearchMapE08] = useState('')
  const [pessoasSearchMapE09, setPessoasSearchMapE09] = useState('')
  const [pessoasSearchMapE10, setPessoasSearchMapE10] = useState('')
  const [pessoasSearchMapE11, setPessoasSearchMapE11] = useState('')
  const [pessoasSearchMapE12, setPessoasSearchMapE12] = useState('')
  const [pessoasSearchMapE13, setPessoasSearchMapE13] = useState('')
  const [pessoasSearchMapE14, setPessoasSearchMapE14] = useState('')
  const [pessoasSearchMapE15, setPessoasSearchMapE15] = useState('')
  const [pessoasSearchMapE16, setPessoasSearchMapE16] = useState('')
  const [pessoasSearchMapE17, setPessoasSearchMapE17] = useState('')
  

  // GALERIA F

  const [pessoasSearchMapISOLAMENTO01, setPessoasSearchMapISOLAMENTO01] = useState('')
  const [pessoasSearchMapISOLAMENTO02, setPessoasSearchMapISOLAMENTO02] = useState('')
  const [pessoasSearchMapISOLAMENTO03, setPessoasSearchMapISOLAMENTO03] = useState('')
  const [pessoasSearchMapISOLAMENTO04, setPessoasSearchMapISOLAMENTO04] = useState('')
  const [pessoasSearchMapISOLAMENTO05, setPessoasSearchMapISOLAMENTO05] = useState('')
  const [pessoasSearchMapISOLAMENTO06, setPessoasSearchMapISOLAMENTO06] = useState('')
  const [pessoasSearchMapISOLAMENTO07, setPessoasSearchMapISOLAMENTO07] = useState('')
  const [pessoasSearchMapISOLAMENTO08, setPessoasSearchMapISOLAMENTO08] = useState('')
  const [pessoasSearchMapISOLAMENTO09, setPessoasSearchMapISOLAMENTO09] = useState('')
  const [pessoasSearchMapISOLAMENTO10, setPessoasSearchMapISOLAMENTO10] = useState('')
  

  // GALERIA G
  

  //variaveis da triagem


  const [pessoasSearchMapTR00, setPessoasSearchMapTR00] = useState('')
  const [pessoasSearchMapTR01, setPessoasSearchMapTR01] = useState('')
  const [pessoasSearchMapTR02, setPessoasSearchMapTR02] = useState('')
  const [pessoasSearchMapTR03, setPessoasSearchMapTR03] = useState('')
  const [pessoasSearchMapTR04, setPessoasSearchMapTR04] = useState('')
 

  //CRIAÇÃO DAS VARIÁVEIS DE PRESOS FORA DA UNIDADE

  const [pessoasDomiciliartotal, setPessoasDomiciliartotal] = useState('')
  const [pessoasHospitaltotal, setPessoasHospitaltotal] = useState('')
  const [pessoasPernoitetotal, setPessoasPernoitetotal] = useState('')



  // criação das variáveis de totalização do componente Cela
  const [pessoasSearchMapA01total, setPessoasSearchMapA01total] = useState('')
  const [pessoasSearchMapA02total, setPessoasSearchMapA02total] = useState('')
  const [pessoasSearchMapA03total, setPessoasSearchMapA03total] = useState('')
  const [pessoasSearchMapA04total, setPessoasSearchMapA04total] = useState('')
  const [pessoasSearchMapA05total, setPessoasSearchMapA05total] = useState('')
  const [pessoasSearchMapA06total, setPessoasSearchMapA06total] = useState('')
  const [pessoasSearchMapA07total, setPessoasSearchMapA07total] = useState('')
  const [pessoasSearchMapA08total, setPessoasSearchMapA08total] = useState('')
  const [pessoasSearchMapA09total, setPessoasSearchMapA09total] = useState('')
  const [pessoasSearchMapA10total, setPessoasSearchMapA10total] = useState('')
  const [pessoasSearchMapA11total, setPessoasSearchMapA11total] = useState('')
  const [pessoasSearchMapA12total, setPessoasSearchMapA12total] = useState('')
 
  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB00total, setPessoasSearchMapB00total] = useState('')
  const [pessoasSearchMapB01total, setPessoasSearchMapB01total] = useState('')
  const [pessoasSearchMapB02total, setPessoasSearchMapB02total] = useState('')
  const [pessoasSearchMapB03total, setPessoasSearchMapB03total] = useState('')
  const [pessoasSearchMapB04total, setPessoasSearchMapB04total] = useState('')
  const [pessoasSearchMapB05total, setPessoasSearchMapB05total] = useState('')
  const [pessoasSearchMapB06total, setPessoasSearchMapB06total] = useState('')
  const [pessoasSearchMapB07total, setPessoasSearchMapB07total] = useState('')
  const [pessoasSearchMapB08total, setPessoasSearchMapB08total] = useState('')
  const [pessoasSearchMapB09total, setPessoasSearchMapB09total] = useState('')
  const [pessoasSearchMapB10total, setPessoasSearchMapB10total] = useState('')
  const [pessoasSearchMapB11total, setPessoasSearchMapB11total] = useState('')
  const [pessoasSearchMapB12total, setPessoasSearchMapB12total] = useState('')
  const [pessoasSearchMapB13total, setPessoasSearchMapB13total] = useState('')
  const [pessoasSearchMapB14total, setPessoasSearchMapB14total] = useState('')
  const [pessoasSearchMapB15total, setPessoasSearchMapB15total] = useState('')
  
  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC00total, setPessoasSearchMapC00total] = useState('')
  const [pessoasSearchMapC01total, setPessoasSearchMapC01total] = useState('')
  const [pessoasSearchMapC02total, setPessoasSearchMapC02total] = useState('')
  const [pessoasSearchMapC03total, setPessoasSearchMapC03total] = useState('')
  const [pessoasSearchMapC04total, setPessoasSearchMapC04total] = useState('')
  const [pessoasSearchMapC05total, setPessoasSearchMapC05total] = useState('')
  const [pessoasSearchMapC06total, setPessoasSearchMapC06total] = useState('')
  const [pessoasSearchMapC07total, setPessoasSearchMapC07total] = useState('')
  const [pessoasSearchMapC08total, setPessoasSearchMapC08total] = useState('')
  const [pessoasSearchMapC09total, setPessoasSearchMapC09total] = useState('')
  const [pessoasSearchMapC10total, setPessoasSearchMapC10total] = useState('')
  const [pessoasSearchMapC11total, setPessoasSearchMapC11total] = useState('')
  const [pessoasSearchMapC12total, setPessoasSearchMapC12total] = useState('')
  const [pessoasSearchMapC13total, setPessoasSearchMapC13total] = useState('')
  const [pessoasSearchMapC14total, setPessoasSearchMapC14total] = useState('')
  const [pessoasSearchMapC15total, setPessoasSearchMapC15total] = useState('')
  const [pessoasSearchMapC16total, setPessoasSearchMapC16total] = useState('')
  const [pessoasSearchMapC17total, setPessoasSearchMapC17total] = useState('')
  

  // CRIAÇÃO DAS VARIAVEIS GALERIA D
  const [pessoasSearchMapD00total, setPessoasSearchMapD00total] = useState('')
  const [pessoasSearchMapD01total, setPessoasSearchMapD01total] = useState('')
  const [pessoasSearchMapD02total, setPessoasSearchMapD02total] = useState('')
  const [pessoasSearchMapD03total, setPessoasSearchMapD03total] = useState('')
  const [pessoasSearchMapD04total, setPessoasSearchMapD04total] = useState('')
  const [pessoasSearchMapD05total, setPessoasSearchMapD05total] = useState('')
  const [pessoasSearchMapD06total, setPessoasSearchMapD06total] = useState('')
  const [pessoasSearchMapD07total, setPessoasSearchMapD07total] = useState('')
  const [pessoasSearchMapD08total, setPessoasSearchMapD08total] = useState('')
  const [pessoasSearchMapD09total, setPessoasSearchMapD09total] = useState('')
  const [pessoasSearchMapD10total, setPessoasSearchMapD10total] = useState('')
  const [pessoasSearchMapD11total, setPessoasSearchMapD11total] = useState('')
  const [pessoasSearchMapD12total, setPessoasSearchMapD12total] = useState('')
  const [pessoasSearchMapD13total, setPessoasSearchMapD13total] = useState('')
  const [pessoasSearchMapD14total, setPessoasSearchMapD14total] = useState('')
  const [pessoasSearchMapD15total, setPessoasSearchMapD15total] = useState('')
  const [pessoasSearchMapD16total, setPessoasSearchMapD16total] = useState('')
  const [pessoasSearchMapD17total, setPessoasSearchMapD17total] = useState('')
 

  //GALERIA E

  const [pessoasSearchMapE00total, setPessoasSearchMapE00total] = useState('')
  const [pessoasSearchMapE01total, setPessoasSearchMapE01total] = useState('')
  const [pessoasSearchMapE02total, setPessoasSearchMapE02total] = useState('')
  const [pessoasSearchMapE03total, setPessoasSearchMapE03total] = useState('')
  const [pessoasSearchMapE04total, setPessoasSearchMapE04total] = useState('')
  const [pessoasSearchMapE05total, setPessoasSearchMapE05total] = useState('')
  const [pessoasSearchMapE06total, setPessoasSearchMapE06total] = useState('')
  const [pessoasSearchMapE07total, setPessoasSearchMapE07total] = useState('')
  const [pessoasSearchMapE08total, setPessoasSearchMapE08total] = useState('')
  const [pessoasSearchMapE09total, setPessoasSearchMapE09total] = useState('')
  const [pessoasSearchMapE10total, setPessoasSearchMapE10total] = useState('')
  const [pessoasSearchMapE11total, setPessoasSearchMapE11total] = useState('')
  const [pessoasSearchMapE12total, setPessoasSearchMapE12total] = useState('')
  const [pessoasSearchMapE13total, setPessoasSearchMapE13total] = useState('')
  const [pessoasSearchMapE14total, setPessoasSearchMapE14total] = useState('')
  const [pessoasSearchMapE15total, setPessoasSearchMapE15total] = useState('')
  const [pessoasSearchMapE16total, setPessoasSearchMapE16total] = useState('')
  const [pessoasSearchMapE17total, setPessoasSearchMapE17total] = useState('')
  

  const [pessoasSearchMapISOLAMENTO01total, setPessoasSearchMapISOLAMENTO01total] = useState('')
  const [pessoasSearchMapISOLAMENTO02total, setPessoasSearchMapISOLAMENTO02total] = useState('')
  const [pessoasSearchMapISOLAMENTO03total, setPessoasSearchMapISOLAMENTO03total] = useState('')
  const [pessoasSearchMapISOLAMENTO04total, setPessoasSearchMapISOLAMENTO04total] = useState('')
  const [pessoasSearchMapISOLAMENTO05total, setPessoasSearchMapISOLAMENTO05total] = useState('')
  const [pessoasSearchMapISOLAMENTO06total, setPessoasSearchMapISOLAMENTO06total] = useState('')
  const [pessoasSearchMapISOLAMENTO07total, setPessoasSearchMapISOLAMENTO07total] = useState('')
  const [pessoasSearchMapISOLAMENTO08total, setPessoasSearchMapISOLAMENTO08total] = useState('')
  const [pessoasSearchMapISOLAMENTO09total, setPessoasSearchMapISOLAMENTO09total] = useState('')
  const [pessoasSearchMapISOLAMENTO10total, setPessoasSearchMapISOLAMENTO10total] = useState('')

  
 

  const [pessoasSearchMapTR00total, setPessoasSearchMapTR00total] = useState('')
  const [pessoasSearchMapTR01total, setPessoasSearchMapTR01total] = useState('')
  const [pessoasSearchMapTR02total, setPessoasSearchMapTR02total] = useState('')
  const [pessoasSearchMapTR03total, setPessoasSearchMapTR03total] = useState('')
  const [pessoasSearchMapTR04total, setPessoasSearchMapTR04total] = useState('')




  


  // Criação das variáveis toalizadoras de valores
  const totalSomaA =
    pessoasSearchMapA01total +
    pessoasSearchMapA02total +
    pessoasSearchMapA03total +
    pessoasSearchMapA04total +
    pessoasSearchMapA05total +
    pessoasSearchMapA06total +
    pessoasSearchMapA07total +
    pessoasSearchMapA08total +
    pessoasSearchMapA09total +
    pessoasSearchMapA10total+
    pessoasSearchMapA11total+
    pessoasSearchMapA12total


    const totalISOLAMENTO =
    pessoasSearchMapISOLAMENTO01total +
    pessoasSearchMapISOLAMENTO02total +
    pessoasSearchMapISOLAMENTO03total +
    pessoasSearchMapISOLAMENTO04total +
    pessoasSearchMapISOLAMENTO05total +
    pessoasSearchMapISOLAMENTO06total +
    pessoasSearchMapISOLAMENTO07total +
    pessoasSearchMapISOLAMENTO08total +
    pessoasSearchMapISOLAMENTO09total +
    pessoasSearchMapISOLAMENTO10total

  // GALERIA BRAVO 
  const totalSomaB =
    pessoasSearchMapB00total +
    pessoasSearchMapB01total +
    pessoasSearchMapB02total +
    pessoasSearchMapB03total +
    pessoasSearchMapB04total +
    pessoasSearchMapB05total +
    pessoasSearchMapB06total +
    pessoasSearchMapB07total +
    pessoasSearchMapB08total +
    pessoasSearchMapB09total +
    pessoasSearchMapB10total +
    pessoasSearchMapB11total +
    pessoasSearchMapB12total +
    pessoasSearchMapB13total +
    pessoasSearchMapB14total +
    pessoasSearchMapB15total 

   


  // QUADRANTES BRAVO 


  // GALERIA CHARLIE


  const totalSomaC =
    pessoasSearchMapC00total +
    pessoasSearchMapC01total +
    pessoasSearchMapC02total +
    pessoasSearchMapC03total +
    pessoasSearchMapC04total +
    pessoasSearchMapC05total +
    pessoasSearchMapC06total +
    pessoasSearchMapC07total +
    pessoasSearchMapC08total +
    pessoasSearchMapC09total +
    pessoasSearchMapC10total +
    pessoasSearchMapC11total +
    pessoasSearchMapC12total +
    pessoasSearchMapC13total +
    pessoasSearchMapC14total +
    pessoasSearchMapC15total +
    pessoasSearchMapC16total +
    pessoasSearchMapC17total 
    


  // QUADRANTES CHARLIE 






  const totalSomaD =
  pessoasSearchMapD00total +
  pessoasSearchMapD01total +
  pessoasSearchMapD02total +
  pessoasSearchMapD03total +
  pessoasSearchMapD04total +
  pessoasSearchMapD05total +
  pessoasSearchMapD06total +
  pessoasSearchMapD07total +
  pessoasSearchMapD08total +
  pessoasSearchMapD09total +
  pessoasSearchMapD10total +
  pessoasSearchMapD11total +
  pessoasSearchMapD12total +
  pessoasSearchMapD13total +
  pessoasSearchMapD14total +
  pessoasSearchMapD15total +
  pessoasSearchMapD16total +
  pessoasSearchMapD17total 


  const totalSomaE =
    pessoasSearchMapE00total +
    pessoasSearchMapE01total +
    pessoasSearchMapE02total +
    pessoasSearchMapE03total +
    pessoasSearchMapE04total +
    pessoasSearchMapE05total +
    pessoasSearchMapE06total +
    pessoasSearchMapE07total +
    pessoasSearchMapE08total +
    pessoasSearchMapE09total +
    pessoasSearchMapE10total +
    pessoasSearchMapE11total +
    pessoasSearchMapE12total +
    pessoasSearchMapE13total +
    pessoasSearchMapE14total +
    pessoasSearchMapE15total +
    pessoasSearchMapE16total +
    pessoasSearchMapE17total 

  
   

 
  const totalSomaTR =
    pessoasSearchMapTR00total +
    pessoasSearchMapTR01total +
    pessoasSearchMapTR02total +
    pessoasSearchMapTR03total +
    pessoasSearchMapTR04total 
 


  const totalUp =
    totalSomaA +
    totalSomaB +
    totalSomaC +
    totalSomaD +
    totalSomaE +
    totalSomaTR +
    totalISOLAMENTO


  const totalGeral = totalUp + pessoasDomiciliartotal + pessoasHospitaltotal + pessoasPernoitetotal


  useEffect(() => {
    if (totalGeral > 0) {
      setTimeout(() => {

        setIsSkeletonLoading(false);
      }, 1000);
    }
  }, [totalGeral]);





  const paginaTotal = () => {

    setAtivaBotao(1)
    setPagina(1)


  }
  const paginaGaleriaA = () => {
    setAtivaBotao(2)
    setPagina(2)

  }
  const paginaGaleriaB = () => {
    setAtivaBotao(3)
    setPagina(3)

  }
  const paginaGaleriaC = () => {

    setAtivaBotao(4)
    setPagina(4)

  }
  const paginaGaleriaD = () => {

    setAtivaBotao(5)
    setPagina(5)

  }
  const paginaGaleriaE = () => {

    setAtivaBotao(9)
    setPagina(9)

  }
  const paginaGaleriaF = () => {

    setAtivaBotao(10)
    setPagina(10)

  }
  
  
  const paginaGaleriaTR = () => {

    setAtivaBotao(6)
    setPagina(6)

  }

  const paginaForaUP = () => {

    setAtivaBotao(7)
    setPagina(7)

  }


  // A101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA01 = "A01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA01(filteredResults);
      setPessoasSearchMapA01total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA01).toUpperCase())
  }, [galerias])

  // A02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA02 = "A02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA02(filteredResults);
      setPessoasSearchMapA02total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA02).toUpperCase())
  }, [galerias])

  // A03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA03 = "A03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA03(filteredResults);
      setPessoasSearchMapA03total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA03).toUpperCase())
  }, [galerias])

  // A04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA04 = "A04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA04(filteredResults);
      setPessoasSearchMapA04total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA04).toUpperCase())
  }, [galerias])

  // A05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA05 = "A05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA05(filteredResults);
      setPessoasSearchMapA05total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA05).toUpperCase())
  }, [galerias])

  // A06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA06 = "A06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA06(filteredResults);
      setPessoasSearchMapA06total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA06).toUpperCase())
  }, [galerias])

  // A07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA07 = "A07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA07(filteredResults);
      setPessoasSearchMapA07total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA07).toUpperCase())
  }, [galerias])


  // A08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA08 = "A08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA08(filteredResults);
      setPessoasSearchMapA08total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA08).toUpperCase())
  }, [galerias])
  // A09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA09 = "A09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA09(filteredResults);
      setPessoasSearchMapA09total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA09).toUpperCase())
  }, [galerias])

  // A10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA10 = "A10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA10(filteredResults);
      setPessoasSearchMapA10total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA10).toUpperCase())
  }, [galerias])
 

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA11 = "A11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA11(filteredResults);
      setPessoasSearchMapA11total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA11).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA12 = "A12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA12(filteredResults);
      setPessoasSearchMapA12total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA12).toUpperCase())
  }, [galerias])
 

  // B00
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB00 = "B00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB00(filteredResults);
      setPessoasSearchMapB00total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB00).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB01 = "B01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB01(filteredResults);
      setPessoasSearchMapB01total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB01).toUpperCase())
  }, [galerias])

  // B02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB02 = "B02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB02(filteredResults);
      setPessoasSearchMapB02total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB02).toUpperCase())
  }, [galerias])

  // B03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB03 = "B03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB03(filteredResults);
      setPessoasSearchMapB03total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB03).toUpperCase())
  }, [galerias])

  // B04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB04 = "B04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB04(filteredResults);
      setPessoasSearchMapB04total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB04).toUpperCase())
  }, [galerias])
  // B05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB05 = "B05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB05(filteredResults);
      setPessoasSearchMapB05total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB05).toUpperCase())
  }, [galerias])

  // B06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB06 = "B06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB06(filteredResults);
      setPessoasSearchMapB06total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB06).toUpperCase())
  }, [galerias])

  // B07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB07 = "B07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB07(filteredResults);
      setPessoasSearchMapB07total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB07).toUpperCase())
  }, [galerias])


  // B08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB08 = "B08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB08(filteredResults);
      setPessoasSearchMapB08total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB08).toUpperCase())
  }, [galerias])
  // B09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB09 = "B09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB09(filteredResults);
      setPessoasSearchMapB09total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB09).toUpperCase())
  }, [galerias])

  // B10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB10 = "B10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB10(filteredResults);
      setPessoasSearchMapB10total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB10).toUpperCase())
  }, [galerias])
  // B11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB11 = "B11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB11(filteredResults);
      setPessoasSearchMapB11total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB11).toUpperCase())
  }, [galerias])

  // B12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB12 = "B12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB12(filteredResults);
      setPessoasSearchMapB12total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB12).toUpperCase())
  }, [galerias])
  // B12

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB13 = "B13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB13(filteredResults);
      setPessoasSearchMapB13total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB13).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB14 = "B14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB14(filteredResults);
      setPessoasSearchMapB14total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB14).toUpperCase())
  }, [galerias])



  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB15 = "B15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB15(filteredResults);
      setPessoasSearchMapB15total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB15).toUpperCase())
  }, [galerias])



  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC00 = "C00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC00(filteredResults);
      setPessoasSearchMapC00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC00).toUpperCase())
  }, [galerias])
  //C C01


  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC01 = "C01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC01(filteredResults);
      setPessoasSearchMapC01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC01).toUpperCase())
  }, [galerias])

  // C02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC02 = "C02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC02(filteredResults);
      setPessoasSearchMapC02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC02).toUpperCase())
  }, [galerias])

  // C03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC03 = "C03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC03(filteredResults);
      setPessoasSearchMapC03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC03).toUpperCase())
  }, [galerias])

  // C04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC04 = "C04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC04(filteredResults);
      setPessoasSearchMapC04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC04).toUpperCase())
  }, [galerias])
  // C05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC05 = "C05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC05(filteredResults);
      setPessoasSearchMapC05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC05).toUpperCase())
  }, [galerias])

  // C06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC06 = "C06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC06(filteredResults);
      setPessoasSearchMapC06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC06).toUpperCase())
  }, [galerias])

  // C07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC07 = "C07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC07(filteredResults);
      setPessoasSearchMapC07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC07).toUpperCase())
  }, [galerias])


  // C08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC08 = "C08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC08(filteredResults);
      setPessoasSearchMapC08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC08).toUpperCase())
  }, [galerias])
  // C09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC09 = "C09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC09(filteredResults);
      setPessoasSearchMapC09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC10 = "C10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC10(filteredResults);
      setPessoasSearchMapC10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC10).toUpperCase())
  }, [galerias])
  // C11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC11 = "C11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC11(filteredResults);
      setPessoasSearchMapC11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC11).toUpperCase())
  }, [galerias])

  // C12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC12 = "C12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC12(filteredResults);
      setPessoasSearchMapC12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC12).toUpperCase())
  }, [galerias])


  // C13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC13 = "C13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC13(filteredResults);
      setPessoasSearchMapC13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC13).toUpperCase())
  }, [galerias])

  // C14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC14 = "C14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC14(filteredResults);
      setPessoasSearchMapC14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC14).toUpperCase())
  }, [galerias])

  // C15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC15 = "C15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC15(filteredResults);
      setPessoasSearchMapC15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC15).toUpperCase())
  }, [galerias])


  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC16 = "C16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC16(filteredResults);
      setPessoasSearchMapC16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC16).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC17 = "C17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC17(filteredResults);
      setPessoasSearchMapC17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC17).toUpperCase())
  }, [galerias])

  

  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD00 = "D00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD00(filteredResults);
      setPessoasSearchMapD00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD00).toUpperCase())
  }, [galerias])
  
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD01 = "D01";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD01(filteredResults);
      setPessoasSearchMapD01total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD01).toUpperCase())
  }, [galerias])
  
  // D02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD02 = "D02";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD02(filteredResults);
      setPessoasSearchMapD02total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD02).toUpperCase())
  }, [galerias])
  
  // D03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD03 = "D03";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD03(filteredResults);
      setPessoasSearchMapD03total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD03).toUpperCase())
  }, [galerias])
  
  // D04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD04 = "D04";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD04(filteredResults);
      setPessoasSearchMapD04total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD04).toUpperCase())
  }, [galerias])
  // D05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD05 = "D05";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD05(filteredResults);
      setPessoasSearchMapD05total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD05).toUpperCase())
  }, [galerias])
  
  // D06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD06 = "D06";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD06(filteredResults);
      setPessoasSearchMapD06total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD06).toUpperCase())
  }, [galerias])
  
  // D07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD07 = "D07";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD07(filteredResults);
      setPessoasSearchMapD07total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD07).toUpperCase())
  }, [galerias])
  
  
  // D08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD08 = "D08";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD08(filteredResults);
      setPessoasSearchMapD08total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD08).toUpperCase())
  }, [galerias])
  // D09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD09 = "D09";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD09(filteredResults);
      setPessoasSearchMapD09total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD09).toUpperCase())
  }, [galerias])
  
  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD10 = "D10";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD10(filteredResults);
      setPessoasSearchMapD10total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD10).toUpperCase())
  }, [galerias])
  // D11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD11 = "D11";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD11(filteredResults);
      setPessoasSearchMapD11total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD11).toUpperCase())
  }, [galerias])
  
  // D12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD12 = "D12";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD12(filteredResults);
      setPessoasSearchMapD12total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD12).toUpperCase())
  }, [galerias])
  
  
  // D13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD13 = "D13";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD13(filteredResults);
      setPessoasSearchMapD13total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD13).toUpperCase())
  }, [galerias])
  
  // D14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD14 = "D14";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD14(filteredResults);
      setPessoasSearchMapD14total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD14).toUpperCase())
  }, [galerias])
  
  // D15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD15 = "D15";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD15(filteredResults);
      setPessoasSearchMapD15total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD15).toUpperCase())
  }, [galerias])
  
  // D16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD16 = "D16";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD16(filteredResults);
      setPessoasSearchMapD16total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD16).toUpperCase())
  }, [galerias])
  
  
  // D17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD17 = "D17";
  
    function performSearch(searchTerm) {
      let data = array
  
      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );
  
      setPessoasSearchMapD17(filteredResults);
      setPessoasSearchMapD17total(filteredResults.length)
  
    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD17).toUpperCase())
  }, [galerias])
  
  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE00 = "E00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE00(filteredResults);
      setPessoasSearchMapE00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE00).toUpperCase())
  }, [galerias])
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE01 = "E01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE01(filteredResults);
      setPessoasSearchMapE01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE01).toUpperCase())
  }, [galerias])

  // E02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE02 = "E02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE02(filteredResults);
      setPessoasSearchMapE02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE02).toUpperCase())
  }, [galerias])

  // E03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE03 = "E03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE03(filteredResults);
      setPessoasSearchMapE03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE03).toUpperCase())
  }, [galerias])

  // E04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE04 = "E04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE04(filteredResults);
      setPessoasSearchMapE04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE04).toUpperCase())
  }, [galerias])
  // E05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE05 = "E05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE05(filteredResults);
      setPessoasSearchMapE05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE05).toUpperCase())
  }, [galerias])

  // E06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE06 = "E06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE06(filteredResults);
      setPessoasSearchMapE06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE06).toUpperCase())
  }, [galerias])

  // E07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE07 = "E07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE07(filteredResults);
      setPessoasSearchMapE07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE07).toUpperCase())
  }, [galerias])


  // E08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE08 = "E08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE08(filteredResults);
      setPessoasSearchMapE08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE08).toUpperCase())
  }, [galerias])
  // E09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE09 = "E09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE09(filteredResults);
      setPessoasSearchMapE09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE10 = "E10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE10(filteredResults);
      setPessoasSearchMapE10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE10).toUpperCase())
  }, [galerias])
  // E11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE11 = "E11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE11(filteredResults);
      setPessoasSearchMapE11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE11).toUpperCase())
  }, [galerias])

  // E12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE12 = "E12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE12(filteredResults);
      setPessoasSearchMapE12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE12).toUpperCase())
  }, [galerias])


  // E13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE13 = "E13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE13(filteredResults);
      setPessoasSearchMapE13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE13).toUpperCase())
  }, [galerias])

  // E14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE14 = "E14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE14(filteredResults);
      setPessoasSearchMapE14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE14).toUpperCase())
  }, [galerias])

  // E15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE15 = "E15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE15(filteredResults);
      setPessoasSearchMapE15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE15).toUpperCase())
  }, [galerias])

  // E16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE16 = "E16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE16(filteredResults);
      setPessoasSearchMapE16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE16).toUpperCase())
  }, [galerias])


  // E17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE17 = "E17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE17(filteredResults);
      setPessoasSearchMapE17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE17).toUpperCase())
  }, [galerias])

  

  // GALERIA F

  
  // TR201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR00 = "TR00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR00(filteredResults);
      setPessoasSearchMapTR00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR00).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR01 = "TR01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR01(filteredResults);
      setPessoasSearchMapTR01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR01).toUpperCase())
  }, [galerias])


  // TR02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR02 = "TR02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR02(filteredResults);
      setPessoasSearchMapTR02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR02).toUpperCase())
  }, [galerias])



  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR03 = "TR03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR03(filteredResults);
      setPessoasSearchMapTR03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR03).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR04 = "TR04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR04(filteredResults);
      setPessoasSearchMapTR04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR04).toUpperCase())
  }, [galerias])

 


  // DOMICILIAR
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let domiciliar_saude = "DOMICILIAR";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasDomiciliar(filteredResults);
      setPessoasDomiciliartotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((domiciliar_saude).toUpperCase())
  }, [galerias])




  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let hospital = "HOSPITAL";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasHospital(filteredResults);
      setPessoasHospitaltotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((hospital).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let pernoite = "PERNOITE";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasPernoite(filteredResults);
      setPessoasPernoitetotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((pernoite).toUpperCase())
  }, [galerias])
  
  
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO01 = "ISOLAMENTO01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO01(filteredResults);
      setPessoasSearchMapISOLAMENTO01total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO01).toUpperCase())
  }, [galerias])

  // ISOLAMENTO02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO02 = "ISOLAMENTO02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO02(filteredResults);
      setPessoasSearchMapISOLAMENTO02total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO02).toUpperCase())
  }, [galerias])

  // ISOLAMENTO03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO03 = "ISOLAMENTO03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO03(filteredResults);
      setPessoasSearchMapISOLAMENTO03total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO03).toUpperCase())
  }, [galerias])

  // ISOLAMENTO04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO04 = "ISOLAMENTO04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO04(filteredResults);
      setPessoasSearchMapISOLAMENTO04total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO04).toUpperCase())
  }, [galerias])

  // ISOLAMENTO05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO05 = "ISOLAMENTO05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO05(filteredResults);
      setPessoasSearchMapISOLAMENTO05total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO05).toUpperCase())
  }, [galerias])

  // ISOLAMENTO06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO06 = "ISOLAMENTO06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO06(filteredResults);
      setPessoasSearchMapISOLAMENTO06total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO06).toUpperCase())
  }, [galerias])

  // ISOLAMENTO07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO07 = "ISOLAMENTO07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO07(filteredResults);
      setPessoasSearchMapISOLAMENTO07total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO07).toUpperCase())
  }, [galerias])


  // ISOLAMENTO08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO08 = "ISOLAMENTO08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO08(filteredResults);
      setPessoasSearchMapISOLAMENTO08total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO08).toUpperCase())
  }, [galerias])
  // ISOLAMENTO09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO09 = "ISOLAMENTO09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO09(filteredResults);
      setPessoasSearchMapISOLAMENTO09total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO09).toUpperCase())
  }, [galerias])

  // A10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO10 = "ISOLAMENTO10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO10(filteredResults);
      setPessoasSearchMapISOLAMENTO10total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO10).toUpperCase())
  }, [galerias])




  return (<>
    {loading && <Loading></Loading>}
    <div className={styles.divtabs}>


      <Modal isOpen={modalBuscaAberto} toggle={toggleModalBusca} style={{ maxWidth: '1300px', width: '100%' }}>
        <ModalBody>
          <div style={{ marginTop: 15, fontFamily: 'policiapenal' }}><center> <p style={{ fontFamily: 'policiapenal', padding: 10, }}><img src={logo} alt="Logo" height={80} style={{ marginBottom: 10 }} /><h2>POLICIA PENAL | ES</h2><h5><b>BUSCA AVANÇADA</b></h5> </p> </center></div>

          <center>

            <input
              type="text"
              autoFocus
              value={termoBusca}
              onChange={(e) => setTermoBusca(e.target.value.toLocaleUpperCase())}
              placeholder="Nome, Infopen, Data de Entrada, Cela, Artigo ..."
              ref={inputBuscaRef}
              style={{
                padding: '10px',
                fontSize: '20px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                width: '80%',
                boxSizing: 'border-box',
                outline: 'none',
                transition: 'box-shadow 0.3s ease',
                marginBottom: 60,
                marginTop: 30

              }}
            />
            {resultadosBusca.length > 0 && <h5>Registros Encontrados: ({resultadosBusca.length})</h5>}  </center>
          <br></br>
          {resultadosBusca.length > 0 && <thead >

            <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd', borderRadius: '15px' }}>
              <tr style={{ backgroundColor: "#253221", color: '#8a9175', borderRadius: '15px', fontWeight: 'lighter' }}>
                <th style={{ width: '200px', textAlign: 'center', padding: '10px' }}></th>
                <th style={{ width: '380px', textAlign: 'center', padding: '10px' }}>Nome</th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Cela</th>
                <th style={{ width: '120px', textAlign: 'center', padding: '10px' }}>Entrada</th>
                <th style={{ width: '120px', textAlign: 'center', padding: '10px' }}>Infopen</th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}></th>
                <th style={{ width: '150px', textAlign: 'center', padding: '10px' }}>Artigo</th>
              </tr>
            </table></thead>}

          {resultadosBusca.map((resultado, index) => (
            <div key={index}>
              <table onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}

                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  border: '1px solid #ddd',
                  borderRadius: '15px',
                  cursor: 'pointer'
                }}>

                <tbody>

                  <tr onClick={() => handleCliqueBuscaCela(resultado.cela, resultado.nome)} style={{
                    backgroundColor: hoveredIndex === index ? '#b8d5c1' : '#c3c8cd',
                    transition: 'background-color 0.3s ease', marginTop: 40
                  }}>

                    <td style={{ width: '50px', textAlign: 'center' }}>
                      <img
                        alt={resultado.nome}
                        width='auto'
                        height={75}
                        src={resultado.imgURL ? resultado.imgURL : userDef}
                        style={{ width: '80px', cursor: 'pointer', maxWidth: '80px', maxHeight: '80px' }}
                      />
                    </td>
                    <td style={{ width: '350px', textAlign: 'center', padding: '10px' }}><b>{resultado.nome}</b></td>
                    <td style={{ width: '80px', textAlign: 'center', padding: '10px' }}><b>{resultado.cela}</b></td>
                    <td style={{ width: '80px', textAlign: 'center', padding: '10px', fontSize: 14 }}>{resultado.selectDate}</td>
                    <td style={{ width: '100px', textAlign: 'right', padding: '10px' }}>
                      <a target="_blank" rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: 'black', fontSize: 14
                        }}
                        href={'https://infopen.sejus.es.gov.br/Identificacao/Presos.aspx?ID=' + resultado.infopen}>
                        {resultado.infopen} <FaEye />
                      </a>
                    </td>

                    <td style={{ width: '100px', textAlign: 'center', padding: '10px' }}>
                      <a target="_blank" rel="noreferrer"
                        style={{
                          textDecoration: 'none',
                          color: 'black',
                          fontSize: 14
                        }} >

                      </a>
                    </td>
                    <td style={{ width: '100px', textAlign: 'center', padding: '10px', fontSize: 12 }}>{resultado.artigo}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}

        </ModalBody>

        <center> <button onClick={toggleModalBusca} style={{ backgroundColor: 'gray', color: 'white', fontFamily: 'policiapenal', fontSize: 14, padding: 14, marginBottom: 30, borderRadius: 10, border: 1 }}>FECHAR</button></center>
      </Modal>


      <center>
        <div className={styles.containerSelect} style={{ marginTop: 10, marginBottom: 30 }}>
          <select className={styles.selectTablet} style={{ height: 40, fontSize: 15, }} onChange={(event) => {
            const selectedValue = event.target.value;
            switch (selectedValue) {
              case 'galeriaA':
                paginaGaleriaA();
                break;
              case 'galeriaB':
                paginaGaleriaB();
                break;
              case 'galeriaC':
                paginaGaleriaC();
                break;
              case 'galeriaD':
                paginaGaleriaD();
                break;
              case 'galeriaTR':
                paginaGaleriaTR();
                break;
              case 'foraUP':
                paginaForaUP();
                break;
              case 'totalGeral':
                paginaTotal();
                break;
              default:
                break;
            }
          }}>
            <option value="galeriaA" selected={ativaBotao === 2}>GALERIA (A)</option>
            <option value="galeriaB" selected={ativaBotao === 3}>GALERIA (B)</option>
            <option value="galeriaC" selected={ativaBotao === 4}>GALERIA (C)</option>
            <option value="galeriaD" selected={ativaBotao === 5}>GALERIA (D)</option>
            <option value="galeriaTR" selected={ativaBotao === 6}>TRIAGEM (TR)</option>
            <option value="foraUP" selected={ativaBotao === 7}>FORA DA UNIDADE</option>
            <option value="totalGeral" selected={ativaBotao === 1}>QUANTITATIVO</option>
          </select>

        </div>
      </center>

      <div id="tab1" onClick={paginaGaleriaA}  >
        <p className={styles.dataColumnTopo} style={ativaBotao === 2 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }} >GALERIA (A)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab2" onClick={paginaGaleriaB} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 3 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (B)</p>

      </div>

      &nbsp; &nbsp; &nbsp;

      <div id="tab3" onClick={paginaGaleriaC} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 4 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (C)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaD} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 5 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (D)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaE} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 9 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>GALERIA (E)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab4" onClick={paginaGaleriaF} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 10 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>ISOLAMENTO</p>

      </div>
      &nbsp; &nbsp; &nbsp;
          
      <div id="tab5" onClick={paginaGaleriaTR} >
        <p className={styles.dataColumnTopo} style={ativaBotao === 6 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>TRIAGEM (TR)</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab8" onClick={paginaForaUP}>
        <p className={styles.dataColumnTopo} style={ativaBotao === 7 ? { color: "green", cursor: 'pointer', paddingTop: 14 } : { cursor: 'pointer', paddingTop: 14 }}>FORA DA UP</p>

      </div>
      &nbsp; &nbsp; &nbsp;
      <div id="tab6" onClick={paginaTotal}>
        <p className={styles.dataColumnTopo} style={ativaBotao === 1 ? { color: "green", cursor: 'pointer', paddingTop: 14, marginRight: 20 } : { cursor: 'pointer', paddingTop: 14, marginRight: 20 }}>TOTAL</p>
      </div>
      <div onClick={toggleModalBusca} style={{ cursor: 'pointer' }} id="tab10" >
        <button className={styles.dataColumnTopo} style={{ backgroundColor: '#545f34', borderRadius: 5, color: '#b8c495', lineHeight: 2.5, display: 'flex', flexDirection: 'row' }} >BUSCAR &nbsp;<FaSearch style={{ marginTop: 12 }} /></button>
      </div>

    </div>



    {pagina === 1 && <div style={{ fontFamily: 'policiapenal' }}> <center> <h1><strong>CONTAGEM GERAL</strong></h1><p>TOTALIZADORES POR GALERIAS E VAGAS</p><br></br></center>
      <div>
        <center><div className={styles.quadroFora}>
          <Table dark>
            <tbody style={{ boxShadow: '2px 3px 1px #888888', lineHeight: '25px' }} >
              <tr style={{ height: 42 }}>
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }} scope="row"><center>LOCAL</center>
                  <center><b></b></center>

                </th>
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>PRESOS

                </th>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>VAGAS

                </td>

                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", verticalAlign: "middle", letterSpacing: 2, color: '#8a9175' }}>VAGAS %

                </td>
              </tr>
              <tr>
                <th style={{ color: "black", fontSize: "18px", letterSpacing: "0.5px", verticalAlign: "middle", justifyItems: "right", textAlign: "right", backgroundColor: "#959595", fontFamily: "policiapenal", fontWeight: 'bold' }} scope="row">
                  <p><center> &nbsp;&nbsp;&nbsp;GALERIA A </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA B </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA C </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA D </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;GALERIA E </center> </p>
                  <p> <center> &nbsp;&nbsp;&nbsp;ISOLAMENTO </center> </p>
                  <p> <center>&nbsp;&nbsp;&nbsp;TRIAGEM</center> </p>
                </th>
                <td style={{ fontSize: "17px", backgroundColor: "#959595", letterSpacing: "2px", fontWeight: "bold", verticalAlign: "middle", color: "black", paddingLeft: "15px" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaA}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaB}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaC}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaD}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaE}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalISOLAMENTO}</p>)}
                 
                 
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{totalSomaTR}</p>)}
                </td>
                <td className={styles.dataColumnTopo} style={{ verticalAlign: "middle", fontSize: "17px", letterSpacing: "2px", color: "black", backgroundColor: "#959595" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{50 - totalSomaA}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{104 - totalSomaB}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{128 - totalSomaC}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{144 - totalSomaD}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{144 - totalSomaE}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{128 - totalISOLAMENTO}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p>{64 - totalSomaTR}</p>)}                </td>
                <td className={styles.dataColumnTopo} style={{ verticalAlign: "middle", fontSize: "17px", letterSpacing: "2px", color: "black", backgroundColor: "#959595" }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((50 - totalSomaA) * 100) / 762).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((104 - totalSomaB) * 100) / 762).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((128 - totalSomaC) * 100) / 762).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((144 - totalSomaD) * 100) / 762).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((144 - totalSomaE) * 100) / 762).toFixed(1)}%</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((128 - totalISOLAMENTO) * 100) / 762).toFixed(1)}%</p>)}

                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='80%' height='100%' /></p>
                  ) : (<p>{(((64 - totalSomaTR) * 100) / 762).toFixed(1)}%</p>)}

                </td>

              </tr>
              <tr >
                <th style={{ backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175' }} scope="row">
                  <center><b>TOTAL NA UNIDADE</b></center>

                </th>
                <td style={{ fontSize: "17px", backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175', letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong>{totalUp}</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", color: '#8a9175', fontSize: "17px", letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong> {
                    50 - totalSomaA +
                    104 - totalSomaB +
                    128 - totalSomaC +
                    144 - totalSomaD +
                    144 - totalSomaE +
                    128 - totalISOLAMENTO +
                    64 - totalSomaTR
                    }</strong>}

                </td>
                <td className={styles.dataColumnTopo} style={{ letterSpacing: "1px", fontSize: "17px", verticalAlign: "middle", fontWeight: 'bolder', backgroundColor: "#253221", color: '#8a9175' }}>
                  {!isSkeletonLoading && <strong>   {(
                    parseFloat((((50 - totalSomaA) * 100 / 762).toFixed(1))) +
                    parseFloat((((104 - totalSomaB) * 100 / 762).toFixed(1))) +
                    parseFloat((((128 - totalSomaC) * 100 / 762).toFixed(1))) +
                    parseFloat((((128 - totalISOLAMENTO) * 100 / 762).toFixed(1))) +
                    parseFloat((((144 - totalSomaE) * 100 / 762).toFixed(1))) +
                    parseFloat((((144 - totalSomaD) * 100 / 762).toFixed(1))) +
                    parseFloat((((128 - totalISOLAMENTO) * 100 / 762).toFixed(1))) +
                    parseFloat((((64 - totalSomaTR) * 100 / 762).toFixed(1)))
                  ).toFixed(2)} % </strong>}

                </td>


              </tr>
              <tr >
                <th scope="row" style={{ backgroundColor: "#959595", color: 'black', fontFamily:'arial' }}>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>HOSPITAL</center></p>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>PERNOITE</center></p>
                  <p style={{ verticalAlign: "middle", fontSize: "18px", letterSpacing: "2px" }}> <center>DOMICILIAR</center></p>
                </th>
                <td style={{ backgroundColor: "#959595", color: 'black' }}>
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasHospitaltotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasPernoitetotal}</p>)}
                  {isSkeletonLoading ? (
                    <p>  <ShinySkeleton variant="text" width='100%' height='100%' /></p>
                  ) : (<p style={{ fontSize: "17px", letterSpacing: "2px", verticalAlign: "middle", fontWeight: "bold", color: 'black' }}>{pessoasDomiciliartotal}</p>)}
                  
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#959595" }}>

                </td >
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#959595" }}>

                </td>

              </tr>
              <tr>
                <th style={{ borderBottom: "none", backgroundColor: "#253221", verticalAlign: "middle", color: '#8a9175' }} scope="row">
                  <center><b>TOTAL GERAL</b></center>
                </th>
                <td style={{ borderBottom: "none", fontSize: "17px", backgroundColor: "#253221", verticalAlign: "middle", color: "#8a9175", letterSpacing: "1px" }}>
                  {!isSkeletonLoading && <strong>{totalGeral}</strong>}
                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", borderBottom: "none" }}>

                </td>
                <td className={styles.dataColumnTopo} style={{ backgroundColor: "#253221", borderBottom: "none" }}>
                </td>
              </tr>
            </tbody>
          </Table>
        </div></center>
        <br />
      </div>

    </div>}

    <div id="someLista">
      <div className={styles.home}>
        <br></br>
        <br></br>

        {pagina === 2 && <>
          <h1 ><strong>GALERIA "A"</strong>({totalSomaA})</h1>

          <div id="A01"></div><Cela cela="A01" usuario={usuario} celaTotal={pessoasSearchMapA01total} celaMap={(pessoasSearchMapA01 && pessoasSearchMapA01)} nomeBuscado={nomeBuscado} />
          <div id="A02"></div><Cela cela="A02" usuario={usuario} celaTotal={pessoasSearchMapA02total} celaMap={(pessoasSearchMapA02 && pessoasSearchMapA02)} nomeBuscado={nomeBuscado} />
          <div id="A03"></div><Cela cela="A03" usuario={usuario} celaTotal={pessoasSearchMapA03total} celaMap={(pessoasSearchMapA03 && pessoasSearchMapA03)} nomeBuscado={nomeBuscado} />
          <div id="A04"></div><Cela cela="A04" usuario={usuario} celaTotal={pessoasSearchMapA04total} celaMap={(pessoasSearchMapA04 && pessoasSearchMapA04)} nomeBuscado={nomeBuscado} />
          <div id="A05"></div><Cela cela="A05" usuario={usuario} celaTotal={pessoasSearchMapA05total} celaMap={(pessoasSearchMapA05 && pessoasSearchMapA05)} nomeBuscado={nomeBuscado} />
          <div id="A06"></div><Cela cela="A06" usuario={usuario} celaTotal={pessoasSearchMapA06total} celaMap={(pessoasSearchMapA06 && pessoasSearchMapA06)} nomeBuscado={nomeBuscado} />
          <div id="A07"></div><Cela cela="A07" usuario={usuario} celaTotal={pessoasSearchMapA07total} celaMap={(pessoasSearchMapA07 && pessoasSearchMapA07)} nomeBuscado={nomeBuscado} />
          <div id="A08"></div><Cela cela="A08" usuario={usuario} celaTotal={pessoasSearchMapA08total} celaMap={(pessoasSearchMapA08 && pessoasSearchMapA08)} nomeBuscado={nomeBuscado} />
          <div id="A09"></div><Cela cela="A09" usuario={usuario} celaTotal={pessoasSearchMapA09total} celaMap={(pessoasSearchMapA09 && pessoasSearchMapA09)} nomeBuscado={nomeBuscado} />
          <div id="A10"></div><Cela cela="A10" usuario={usuario} celaTotal={pessoasSearchMapA10total} celaMap={(pessoasSearchMapA10 && pessoasSearchMapA10)} nomeBuscado={nomeBuscado} />
          <div id="A11"></div><Cela cela="A11" usuario={usuario} celaTotal={pessoasSearchMapA11total} celaMap={(pessoasSearchMapA11 && pessoasSearchMapA11)} nomeBuscado={nomeBuscado} />
          <div id="A12"></div><Cela cela="A12" usuario={usuario} celaTotal={pessoasSearchMapA12total} celaMap={(pessoasSearchMapA12 && pessoasSearchMapA12)} nomeBuscado={nomeBuscado} />
        
          <br></br><br></br>

        </>}

        {pagina === 3 && <><h1 ><strong>GALERIA "B"</strong>({totalSomaB})</h1>

          <div id="B00"></div> <Cela cela="B00" usuario={usuario} celaTotal={pessoasSearchMapB00total} celaMap={(pessoasSearchMapB00 && pessoasSearchMapB00)} nomeBuscado={nomeBuscado} />
          <div id="B01"></div> <Cela cela="B01" usuario={usuario} celaTotal={pessoasSearchMapB01total} celaMap={(pessoasSearchMapB01 && pessoasSearchMapB01)} nomeBuscado={nomeBuscado} />
          <div id="B02"></div><Cela cela="B02" usuario={usuario} celaTotal={pessoasSearchMapB02total} celaMap={(pessoasSearchMapB02 && pessoasSearchMapB02)} nomeBuscado={nomeBuscado} />
          <div id="B03"></div><Cela cela="B03" usuario={usuario} celaTotal={pessoasSearchMapB03total} celaMap={(pessoasSearchMapB03 && pessoasSearchMapB03)} nomeBuscado={nomeBuscado} />
          <div id="B04"></div><Cela cela="B04" usuario={usuario} celaTotal={pessoasSearchMapB04total} celaMap={(pessoasSearchMapB04 && pessoasSearchMapB04)} nomeBuscado={nomeBuscado} />
          <div id="B05"></div><Cela cela="B05" usuario={usuario} celaTotal={pessoasSearchMapB05total} celaMap={(pessoasSearchMapB05 && pessoasSearchMapB05)} nomeBuscado={nomeBuscado} />
          <div id="B06"></div><Cela cela="B06" usuario={usuario} celaTotal={pessoasSearchMapB06total} celaMap={(pessoasSearchMapB06 && pessoasSearchMapB06)} nomeBuscado={nomeBuscado} />
          <div id="B07"></div><Cela cela="B07" usuario={usuario} celaTotal={pessoasSearchMapB07total} celaMap={(pessoasSearchMapB07 && pessoasSearchMapB07)} nomeBuscado={nomeBuscado} />
          <div id="B08"></div><Cela cela="B08" usuario={usuario} celaTotal={pessoasSearchMapB08total} celaMap={(pessoasSearchMapB08 && pessoasSearchMapB08)} nomeBuscado={nomeBuscado} />
          <div id="B09"></div> <Cela cela="B09" usuario={usuario} celaTotal={pessoasSearchMapB09total} celaMap={(pessoasSearchMapB09 && pessoasSearchMapB09)} nomeBuscado={nomeBuscado} />
          <div id="B10"></div><Cela cela="B10" usuario={usuario} celaTotal={pessoasSearchMapB10total} celaMap={(pessoasSearchMapB10 && pessoasSearchMapB10)} nomeBuscado={nomeBuscado} />
          <div id="B11"></div><Cela cela="B11" usuario={usuario} celaTotal={pessoasSearchMapB11total} celaMap={(pessoasSearchMapB11 && pessoasSearchMapB11)} nomeBuscado={nomeBuscado} />
          <div id="B12"></div><Cela cela="B12" usuario={usuario} celaTotal={pessoasSearchMapB12total} celaMap={(pessoasSearchMapB12 && pessoasSearchMapB12)} nomeBuscado={nomeBuscado} />
          <div id="B13"></div><Cela cela="B13" usuario={usuario} celaTotal={pessoasSearchMapB13total} celaMap={(pessoasSearchMapB13 && pessoasSearchMapB13)} nomeBuscado={nomeBuscado} />
          <div id="B14"></div><Cela cela="B14" usuario={usuario} celaTotal={pessoasSearchMapB14total} celaMap={(pessoasSearchMapB14 && pessoasSearchMapB14)} nomeBuscado={nomeBuscado} />
          <div id="B15"></div><Cela cela="B15" usuario={usuario} celaTotal={pessoasSearchMapB15total} celaMap={(pessoasSearchMapB15 && pessoasSearchMapB15)} nomeBuscado={nomeBuscado} />
        
        </>}


        {pagina === 4 && <>
          <h1><strong>GALERIA "C"</strong>({totalSomaC})</h1>

          <div id="C00"></div><Cela cela="C00" usuario={usuario} celaTotal={pessoasSearchMapC00total} celaMap={(pessoasSearchMapC00 && pessoasSearchMapC00)} nomeBuscado={nomeBuscado} />
          <div id="C01"></div><Cela cela="C01" usuario={usuario} celaTotal={pessoasSearchMapC01total} celaMap={(pessoasSearchMapC01 && pessoasSearchMapC01)} nomeBuscado={nomeBuscado} />
          <div id="C02"></div><Cela cela="C02" usuario={usuario} celaTotal={pessoasSearchMapC02total} celaMap={(pessoasSearchMapC02 && pessoasSearchMapC02)} nomeBuscado={nomeBuscado} />
          <div id="C03"></div><Cela cela="C03" usuario={usuario} celaTotal={pessoasSearchMapC03total} celaMap={(pessoasSearchMapC03 && pessoasSearchMapC03)} nomeBuscado={nomeBuscado} />
          <div id="C04"></div><Cela cela="C04" usuario={usuario} celaTotal={pessoasSearchMapC04total} celaMap={(pessoasSearchMapC04 && pessoasSearchMapC04)} nomeBuscado={nomeBuscado} />
          <div id="C05"></div><Cela cela="C05" usuario={usuario} celaTotal={pessoasSearchMapC05total} celaMap={(pessoasSearchMapC05 && pessoasSearchMapC05)} nomeBuscado={nomeBuscado} />
          <div id="C06"></div><Cela cela="C06" usuario={usuario} celaTotal={pessoasSearchMapC06total} celaMap={(pessoasSearchMapC06 && pessoasSearchMapC06)} nomeBuscado={nomeBuscado} />
          <div id="C07"></div><Cela cela="C07" usuario={usuario} celaTotal={pessoasSearchMapC07total} celaMap={(pessoasSearchMapC07 && pessoasSearchMapC07)} nomeBuscado={nomeBuscado} />
          <div id="C08"></div><Cela cela="C08" usuario={usuario} celaTotal={pessoasSearchMapC08total} celaMap={(pessoasSearchMapC08 && pessoasSearchMapC08)} nomeBuscado={nomeBuscado} />
          <div id="C09"></div><Cela cela="C09" usuario={usuario} celaTotal={pessoasSearchMapC09total} celaMap={(pessoasSearchMapC09 && pessoasSearchMapC09)} nomeBuscado={nomeBuscado} />
          <div id="C10"></div><Cela cela="C10" usuario={usuario} celaTotal={pessoasSearchMapC10total} celaMap={(pessoasSearchMapC10 && pessoasSearchMapC10)} nomeBuscado={nomeBuscado} />
          <div id="C11"></div><Cela cela="C11" usuario={usuario} celaTotal={pessoasSearchMapC11total} celaMap={(pessoasSearchMapC11 && pessoasSearchMapC11)} nomeBuscado={nomeBuscado} />
          <div id="C12"></div><Cela cela="C12" usuario={usuario} celaTotal={pessoasSearchMapC12total} celaMap={(pessoasSearchMapC12 && pessoasSearchMapC12)} nomeBuscado={nomeBuscado} />
          <div id="C13"></div><Cela cela="C13" usuario={usuario} celaTotal={pessoasSearchMapC13total} celaMap={(pessoasSearchMapC13 && pessoasSearchMapC13)} nomeBuscado={nomeBuscado} />
          <div id="C14"></div><Cela cela="C14" usuario={usuario} celaTotal={pessoasSearchMapC14total} celaMap={(pessoasSearchMapC14 && pessoasSearchMapC14)} nomeBuscado={nomeBuscado} />
          <div id="C15"></div><Cela cela="C15" usuario={usuario} celaTotal={pessoasSearchMapC15total} celaMap={(pessoasSearchMapC15 && pessoasSearchMapC15)} nomeBuscado={nomeBuscado} />
          <div id="C16"></div><Cela cela="C16" usuario={usuario} celaTotal={pessoasSearchMapC16total} celaMap={(pessoasSearchMapC16 && pessoasSearchMapC16)} nomeBuscado={nomeBuscado} />
          <div id="C17"></div><Cela cela="C17" usuario={usuario} celaTotal={pessoasSearchMapC17total} celaMap={(pessoasSearchMapC17 && pessoasSearchMapC17)} nomeBuscado={nomeBuscado} />
          
        </>}
        {pagina === 5 && <>
          <h1 ><strong>GALERIA "D"</strong>({totalSomaD})</h1>
          <div id="D00"></div><Cela cela="D00" usuario={usuario} celaTotal={pessoasSearchMapD00total} celaMap={(pessoasSearchMapD00 && pessoasSearchMapD00)} nomeBuscado={nomeBuscado} />
<div id="D01"></div><Cela cela="D01" usuario={usuario} celaTotal={pessoasSearchMapD01total} celaMap={(pessoasSearchMapD01 && pessoasSearchMapD01)} nomeBuscado={nomeBuscado} />
<div id="D02"></div><Cela cela="D02" usuario={usuario} celaTotal={pessoasSearchMapD02total} celaMap={(pessoasSearchMapD02 && pessoasSearchMapD02)} nomeBuscado={nomeBuscado} />
<div id="D03"></div><Cela cela="D03" usuario={usuario} celaTotal={pessoasSearchMapD03total} celaMap={(pessoasSearchMapD03 && pessoasSearchMapD03)} nomeBuscado={nomeBuscado} />
<div id="D04"></div><Cela cela="D04" usuario={usuario} celaTotal={pessoasSearchMapD04total} celaMap={(pessoasSearchMapD04 && pessoasSearchMapD04)} nomeBuscado={nomeBuscado} />
<div id="D05"></div><Cela cela="D05" usuario={usuario} celaTotal={pessoasSearchMapD05total} celaMap={(pessoasSearchMapD05 && pessoasSearchMapD05)} nomeBuscado={nomeBuscado} />
<div id="D06"></div><Cela cela="D06" usuario={usuario} celaTotal={pessoasSearchMapD06total} celaMap={(pessoasSearchMapD06 && pessoasSearchMapD06)} nomeBuscado={nomeBuscado} />
<div id="D07"></div><Cela cela="D07" usuario={usuario} celaTotal={pessoasSearchMapD07total} celaMap={(pessoasSearchMapD07 && pessoasSearchMapD07)} nomeBuscado={nomeBuscado} />
<div id="D08"></div><Cela cela="D08" usuario={usuario} celaTotal={pessoasSearchMapD08total} celaMap={(pessoasSearchMapD08 && pessoasSearchMapD08)} nomeBuscado={nomeBuscado} />
<div id="D09"></div><Cela cela="D09" usuario={usuario} celaTotal={pessoasSearchMapD09total} celaMap={(pessoasSearchMapD09 && pessoasSearchMapD09)} nomeBuscado={nomeBuscado} />
<div id="D10"></div><Cela cela="D10" usuario={usuario} celaTotal={pessoasSearchMapD10total} celaMap={(pessoasSearchMapD10 && pessoasSearchMapD10)} nomeBuscado={nomeBuscado} />
<div id="D11"></div><Cela cela="D11" usuario={usuario} celaTotal={pessoasSearchMapD11total} celaMap={(pessoasSearchMapD11 && pessoasSearchMapD11)} nomeBuscado={nomeBuscado} />
<div id="D12"></div><Cela cela="D12" usuario={usuario} celaTotal={pessoasSearchMapD12total} celaMap={(pessoasSearchMapD12 && pessoasSearchMapD12)} nomeBuscado={nomeBuscado} />
<div id="D13"></div><Cela cela="D13" usuario={usuario} celaTotal={pessoasSearchMapD13total} celaMap={(pessoasSearchMapD13 && pessoasSearchMapD13)} nomeBuscado={nomeBuscado} />
<div id="D14"></div><Cela cela="D14" usuario={usuario} celaTotal={pessoasSearchMapD14total} celaMap={(pessoasSearchMapD14 && pessoasSearchMapD14)} nomeBuscado={nomeBuscado} />
<div id="D15"></div><Cela cela="D15" usuario={usuario} celaTotal={pessoasSearchMapD15total} celaMap={(pessoasSearchMapD15 && pessoasSearchMapD15)} nomeBuscado={nomeBuscado} />

         
        </>}

        {pagina === 9 && <>
          <h1 ><strong>GALERIA "E"</strong>({totalSomaE})</h1>
          <div id="E00"></div><Cela cela="E00" usuario={usuario} celaTotal={pessoasSearchMapE00total} celaMap={(pessoasSearchMapE00 && pessoasSearchMapE00)} nomeBuscado={nomeBuscado} />
          <div id="E01"></div><Cela cela="E01" usuario={usuario} celaTotal={pessoasSearchMapE01total} celaMap={(pessoasSearchMapE01 && pessoasSearchMapE01)} nomeBuscado={nomeBuscado} />
          <div id="E02"></div><Cela cela="E02" usuario={usuario} celaTotal={pessoasSearchMapE02total} celaMap={(pessoasSearchMapE02 && pessoasSearchMapE02)} nomeBuscado={nomeBuscado} />
          <div id="E03"></div><Cela cela="E03" usuario={usuario} celaTotal={pessoasSearchMapE03total} celaMap={(pessoasSearchMapE03 && pessoasSearchMapE03)} nomeBuscado={nomeBuscado} />
          <div id="E04"></div><Cela cela="E04" usuario={usuario} celaTotal={pessoasSearchMapE04total} celaMap={(pessoasSearchMapE04 && pessoasSearchMapE04)} nomeBuscado={nomeBuscado} />
          <div id="E05"></div><Cela cela="E05" usuario={usuario} celaTotal={pessoasSearchMapE05total} celaMap={(pessoasSearchMapE05 && pessoasSearchMapE05)} nomeBuscado={nomeBuscado} />
          <div id="E06"></div><Cela cela="E06" usuario={usuario} celaTotal={pessoasSearchMapE06total} celaMap={(pessoasSearchMapE06 && pessoasSearchMapE06)} nomeBuscado={nomeBuscado} />
          <div id="E07"></div><Cela cela="E07" usuario={usuario} celaTotal={pessoasSearchMapE07total} celaMap={(pessoasSearchMapE07 && pessoasSearchMapE07)} nomeBuscado={nomeBuscado} />
          <div id="E08"></div><Cela cela="E08" usuario={usuario} celaTotal={pessoasSearchMapE08total} celaMap={(pessoasSearchMapE08 && pessoasSearchMapE08)} nomeBuscado={nomeBuscado} />
          <div id="E09"></div><Cela cela="E09" usuario={usuario} celaTotal={pessoasSearchMapE09total} celaMap={(pessoasSearchMapE09 && pessoasSearchMapE09)} nomeBuscado={nomeBuscado} />
          <div id="E10"></div><Cela cela="E10" usuario={usuario} celaTotal={pessoasSearchMapE10total} celaMap={(pessoasSearchMapE10 && pessoasSearchMapE10)} nomeBuscado={nomeBuscado} />
          <div id="E11"></div><Cela cela="E11" usuario={usuario} celaTotal={pessoasSearchMapE11total} celaMap={(pessoasSearchMapE11 && pessoasSearchMapE11)} nomeBuscado={nomeBuscado} />
          <div id="E12"></div><Cela cela="E12" usuario={usuario} celaTotal={pessoasSearchMapE12total} celaMap={(pessoasSearchMapE12 && pessoasSearchMapE12)} nomeBuscado={nomeBuscado} />
          <div id="E13"></div><Cela cela="E13" usuario={usuario} celaTotal={pessoasSearchMapE13total} celaMap={(pessoasSearchMapE13 && pessoasSearchMapE13)} nomeBuscado={nomeBuscado} />
          <div id="E14"></div><Cela cela="E14" usuario={usuario} celaTotal={pessoasSearchMapE14total} celaMap={(pessoasSearchMapE14 && pessoasSearchMapE14)} nomeBuscado={nomeBuscado} />
          <div id="E15"></div><Cela cela="E15" usuario={usuario} celaTotal={pessoasSearchMapE15total} celaMap={(pessoasSearchMapE15 && pessoasSearchMapE15)} nomeBuscado={nomeBuscado} />
          <div id="E16"></div><Cela cela="E16" usuario={usuario} celaTotal={pessoasSearchMapE16total} celaMap={(pessoasSearchMapE16 && pessoasSearchMapE16)} nomeBuscado={nomeBuscado} />
          <div id="E17"></div><Cela cela="E17" usuario={usuario} celaTotal={pessoasSearchMapE17total} celaMap={(pessoasSearchMapE17 && pessoasSearchMapE17)} nomeBuscado={nomeBuscado} />
        
        </>}
        {pagina === 10 && <>
          <h1 ><strong>ISOLAMENTO</strong>({totalISOLAMENTO})</h1>
         
          <div id="ISOLAMENTO01"></div><Cela cela="ISOLAMENTO01" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO01total} celaMap={(pessoasSearchMapISOLAMENTO01 && pessoasSearchMapISOLAMENTO01)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO02"></div><Cela cela="ISOLAMENTO02" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO02total} celaMap={(pessoasSearchMapISOLAMENTO02 && pessoasSearchMapISOLAMENTO02)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO03"></div><Cela cela="ISOLAMENTO03" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO03total} celaMap={(pessoasSearchMapISOLAMENTO03 && pessoasSearchMapISOLAMENTO03)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO04"></div><Cela cela="ISOLAMENTO04" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO04total} celaMap={(pessoasSearchMapISOLAMENTO04 && pessoasSearchMapISOLAMENTO04)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO05"></div><Cela cela="ISOLAMENTO05" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO05total} celaMap={(pessoasSearchMapISOLAMENTO05 && pessoasSearchMapISOLAMENTO05)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO06"></div><Cela cela="ISOLAMENTO06" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO06total} celaMap={(pessoasSearchMapISOLAMENTO06 && pessoasSearchMapISOLAMENTO06)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO07"></div><Cela cela="ISOLAMENTO07" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO07total} celaMap={(pessoasSearchMapISOLAMENTO07 && pessoasSearchMapISOLAMENTO07)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO08"></div><Cela cela="ISOLAMENTO08" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO08total} celaMap={(pessoasSearchMapISOLAMENTO08 && pessoasSearchMapISOLAMENTO08)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO09"></div><Cela cela="ISOLAMENTO09" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO09total} celaMap={(pessoasSearchMapISOLAMENTO09 && pessoasSearchMapISOLAMENTO09)} nomeBuscado={nomeBuscado} />
<div id="ISOLAMENTO10"></div><Cela cela="ISOLAMENTO10" usuario={usuario} celaTotal={pessoasSearchMapISOLAMENTO10total} celaMap={(pessoasSearchMapISOLAMENTO10 && pessoasSearchMapISOLAMENTO10)} nomeBuscado={nomeBuscado} />
        </>}
       
        
        {pagina === 6 && <>
          <h1 ><strong>TRIAGEM "TR"</strong>({totalSomaTR})</h1>

          <div id="TR01"></div><Cela usuario={usuario} cela="TR01" celaTotal={pessoasSearchMapTR01total} celaMap={(pessoasSearchMapTR01 && pessoasSearchMapTR01)} nomeBuscado={nomeBuscado} />
          <div id="TR02"></div><Cela usuario={usuario} cela="TR02" celaTotal={pessoasSearchMapTR02total} celaMap={(pessoasSearchMapTR02 && pessoasSearchMapTR02)} nomeBuscado={nomeBuscado} />
          <div id="TR03"></div><Cela usuario={usuario} cela="TR03" celaTotal={pessoasSearchMapTR03total} celaMap={(pessoasSearchMapTR03 && pessoasSearchMapTR03)} nomeBuscado={nomeBuscado} />
          <div id="TR04"></div><Cela usuario={usuario} cela="TR04" celaTotal={pessoasSearchMapTR04total} celaMap={(pessoasSearchMapTR04 && pessoasSearchMapTR04)} nomeBuscado={nomeBuscado} />
     
        </>}

        {pagina === 7 && <> <h1><strong> PRESOS FORA DA UNIDADE</strong></h1>

          <br></br><br></br>
          <div id="PRISÃO DOMICILIAR"></div> <Cela usuario={usuario} cela="PRISÃO DOMICILIAR " celaTotal={pessoasDomiciliartotal} celaMap={(pessoasDomiciliar && pessoasDomiciliar)} />
          <div id="INTERNAÇÃO HOSPITALAR"></div> <Cela usuario={usuario} cela="INTERNAÇÃO HOSPITALAR" presoForaTitulo={1} celaTotal={pessoasHospitaltotal} celaMap={(pessoasHospital && pessoasHospital)} />
          <div id="PERNOITE FORA"></div><Cela usuario={usuario} cela="PERNOITE FORA" presoForaTitulo={1} celaTotal={pessoasPernoitetotal} celaMap={(pessoasPernoite && pessoasPernoite)} />


          <br />


        </>}


      </div>
      <br></br><br></br><br></br>
    </div>
  </>
  )
}

export default Home