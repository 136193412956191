import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ListLaboral.css'; // Importando o CSS
import JSZip from 'jszip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import { Document, Packer as DocPacker, Paragraph, Table, TableCell, TableRow, TextRun } from 'docx';
import { FaFileWord } from "react-icons/fa";

// Função para exportar para o Word (para dados)
const exportToWord = (dados) => {
  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Lista Laboral",
                bold: true,
                size: 32,
              }),
            ],
          }),
          new Paragraph({
            text: "Dados exportados da listagem",
          }),
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({ children: [new Paragraph("INFOPEN")] }),
                  new TableCell({ children: [new Paragraph("Nome")] }),
                  new TableCell({ children: [new Paragraph("Cela")] }),
                  new TableCell({ children: [new Paragraph("Obs")] }),
                  new TableCell({ children: [new Paragraph("Data")] }),
                  new TableCell({ children: [new Paragraph("Hora")] }),
                  new TableCell({ children: [new Paragraph("Tipo")] }),
                ],
              }),
              ...dados.map((item) =>
                new TableRow({
                  children: [
                    new TableCell({ children: [new Paragraph(item.infopen)] }),
                    new TableCell({ children: [new Paragraph(item.nome)] }),
                    new TableCell({ children: [new Paragraph(item.cela)] }),
                    new TableCell({ children: [new Paragraph(item.obs)] }),
                    new TableCell({ children: [new Paragraph(item.dataAtual)] }),
                    new TableCell({ children: [new Paragraph(item.horaAtual)] }),
                    new TableCell({ children: [new Paragraph(item.tipo)] }),
                  ],
                })
              ),
            ],
          }),
        ],
      },
    ],
  });

  DocPacker.toBlob(doc).then((blob) => {
    saveAs(blob, 'lista_laboral.docx');
  });
};

// Função para exportar para o Word (para dados2)
const exportToWord2 = (dados2) => {
  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "LISTAGEM DE PRESOS NA RUA",
                bold: true,
                size: 32,
              }),
            ],
          }),
          new Paragraph({
            text: "Dados exportados da listagem",
          }),
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({ children: [new Paragraph("INFOPEN")] }),
                  new TableCell({ children: [new Paragraph("Nome")] }),
                  new TableCell({ children: [new Paragraph("Cela")] }),
                  new TableCell({ children: [new Paragraph("Obs")] }),
                  new TableCell({ children: [new Paragraph("Data")] }),
                  new TableCell({ children: [new Paragraph("Hora")] }),
                  new TableCell({ children: [new Paragraph("Tipo")] }),
                ],
              }),
              ...dados2.map((item) =>
                new TableRow({
                  children: [
                    new TableCell({ children: [new Paragraph(item.infopen)] }),
                    new TableCell({ children: [new Paragraph(item.nome)] }),
                    new TableCell({ children: [new Paragraph(item.cela)] }),
                    new TableCell({ children: [new Paragraph(item.obs)] }),
                    new TableCell({ children: [new Paragraph(item.dataAtual)] }),
                    new TableCell({ children: [new Paragraph(item.horaAtual)] }),
                    new TableCell({ children: [new Paragraph(item.tipo)] }),
                  ],
                })
              ),
            ],
          }),
        ],
      },
    ],
  });

  DocPacker.toBlob(doc).then((blob) => {
    saveAs(blob, 'listagem_presos_na_rua.docx');
  });
};

const ListaLaboral = (props) => {
  const [dados, setDados] = useState([]);
  const [dados2, setDados2] = useState([]);
  const [filtros, setFiltros] = useState({
    infopen: '',
    nome: '',
    cela: '',
    tipo: 'TUDO', // Valor inicial do select
    dataAtual: '', // Adiciona um estado para a data
  });
  const [erro, setErro] = useState(null);

  // Função para buscar dados da página 1
  const buscarDados = async () => {
    try {
      const params = { ...filtros };

      if (params.tipo === 'TUDO') {
        delete params.tipo;
      }

      const response = await axios.get('https://alertadiarioes.com/apipevv3/get_laboral.php', { params });

      if (response.status === 200 && Array.isArray(response.data)) {
        setDados(response.data);
        setErro(null);
      } else {
        setDados([]);
        setErro('Nenhum dado encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setErro('Erro ao buscar os dados. Tente novamente.');
      setDados([]);
    }
  };

  // Função para buscar dados da página 2
  const buscarDados2 = async () => {
    try {
      const params = { ...filtros };

      if (params.tipo === 'TUDO') {
        delete params.tipo;
      }

      const response = await axios.get('https://alertadiarioes.com/apipevv3/filtro_retorno.php', { params });

      if (response.status === 200 && Array.isArray(response.data)) {
        setDados2(response.data);
        setErro(null);
      } else {
        setDados2([]);
        setErro('Nenhum dado encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setErro('Erro ao buscar os dados. Tente novamente.');
      setDados2([]);
    }
  };

  // Atualizar os filtros
  const atualizarFiltros = (e) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };

  // Buscar dados de acordo com a página atual
  useEffect(() => {
    if (props.paginaAtual === 2) {
      buscarDados2();
    } else if (props.paginaAtual === 3) {
      buscarDados();
    }
  }, [filtros, props.paginaAtual]);

  return (
    <div className="lista-laboral-container">
      {props.paginaAtual === 2 && <h1 className="lista-laboral-h1">PRESOS NA RUA</h1>}
      {props.paginaAtual === 3 && <h1 className="lista-laboral-h1">TODOS OS REGISTROS</h1>}
      {props.paginaAtual  && (
        <>
          
          <div className="filter-container">
            <input
              type="text"
              name="infopen"
              placeholder="Buscar por INFOPEN"
              value={filtros.infopen}
              onChange={atualizarFiltros}
              className="lista-laboral-input"
            />
            <input
              type="text"
              name="nome"
              placeholder="Buscar por Nome"
              value={filtros.nome}
              onChange={atualizarFiltros}
              className="lista-laboral-input"
            />
            <input
              type="text"
              name="cela"
              placeholder="Buscar por Cela"
              value={filtros.cela}
              onChange={atualizarFiltros}
              className="lista-laboral-input"
            />
            <input
              type="text"
              name="dataAtual"
              placeholder="Buscar por Data (dd/mm/yyyy)"
              value={filtros.dataAtual}
              onChange={atualizarFiltros}
              className="lista-laboral-input"
            />
            <select name="tipo" value={filtros.tipo} onChange={atualizarFiltros} className="lista-laboral-select">
              <option value="TUDO">TUDO</option>
              <option value="RETORNO">ENTRADA</option>
              <option value="SAIDA">SAÍDA</option>
            </select>
            {props.paginaAtual == 3 && <button onClick={() => exportToWord(dados)} className="lista-laboral-button"><FaFileWord />
</button>}
            {props.paginaAtual == 2 && <button onClick={() => exportToWord2(dados2)} className="lista-laboral-button"><FaFileWord />
</button>}
          </div>
        </>
      )}

      {/* Texto com a quantidade de registros encontrados */}
      {props.paginaAtual === 3 && (
        <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#366528' }}>
          ({dados.length}) registros encontrados
        </p>
      )}
      {props.paginaAtual === 2 && (
        <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#366528' }}>
          ({dados2.length}) registros encontrados.
        </p>
      )}

      {/* Renderização da tabela para página 3 */}
      {props.paginaAtual === 3 && (
        <table className="lista-laboral-table">
          <thead>
            <tr>
              <th></th>
              <th>INFOPEN</th>
              <th>Nome</th>
              <th>Cela</th>
              <th>Obs</th>
              <th>Data</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {erro ? (
              <tr>
                <td colSpan="5" style={{ color: 'red' }}>{erro}</td>
              </tr>
            ) : (
              dados.map((item) => (
                <tr key={item.id} style={{
                  backgroundColor: item.tipo === "Saída" ? "#f8d7da" : item.tipo === "Retorno" ? "#d4edda" : "transparent",
                }}>
                  <td><img height={100} src={item.imgURL} alt="Imagem do preso" /></td>
                  <td><a target="_blank" href={`https://infopen.sejus.es.gov.br/Identificacao/Presos.aspx?ID=${item.infopen}`}>{item.infopen}</a></td>
                  <td>{item.nome}</td>
                  <td>{item.cela}</td>
                  <td>{item.obs}</td>
                  <td>{item.dataAtual} às {item.horaAtual}</td>
                  <td>{item.tipo}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}

      {/* Renderização da tabela para página 2 */}
      {props.paginaAtual === 2 && (
        <table className="lista-laboral-table">
          <thead>
            <tr>
              <th></th>
              <th>INFOPEN</th>
              <th>Nome</th>
              <th>Cela</th>
              <th>Obs</th>
              <th>Data</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {erro ? (
              <tr>
                <td colSpan="5" style={{ color: 'red' }}>{erro}</td>
              </tr>
            ) : (
              dados2.map((item) => (
                <tr key={item.id} style={{
                  backgroundColor: item.tipo === "Saída" ? "#f8d7da" : item.tipo === "Retorno" ? "#d4edda" : "transparent",
                }}>
                  <td><img height={100} src={item.imgURL} alt="Imagem do preso" /></td>
                  <td><a target="_blank" href={`https://infopen.sejus.es.gov.br/Identificacao/Presos.aspx?ID=${item.infopen}`}>{item.infopen}</a></td>
                  <td>{item.nome}</td>
                  <td>{item.cela}</td>
                  <td>{item.obs}</td>
                  <td>{item.dataAtual} às {item.horaAtual}</td>
                  <td>{item.tipo}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ListaLaboral;
