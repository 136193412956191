import React, { useState, useEffect, useRef } from 'react';
import { Input, Label } from 'reactstrap';
import axios from 'axios';

import userDef from '../../assets/img/User-Default3.jpg'
import ListaLaboral from "../../components/ListaLaboral"

import { Alert } from 'reactstrap';


const Movimento = () => {
 
  const [inputInfopen, setInputInfopen] = useState('')
  const [dataInfopen, setDataInfopen] = useState([]);
  const [nome, setNome] = useState("")
  const [cela, setCela] = useState("")
  const [infopen, setInfopen] = useState("")
  const [artigo, setArtigo] = useState("")
  const [imgURL, setImgURL] = useState("")
  const [selecao, setSelecao] = useState("");
  const [error, setError] = useState(null);
  const [dataAtual, setDataAtual] = useState('');
  const [horaAtual, setHoraAtual] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
const [ tipo , setTipo] = useState('saida')
const [paginaAtualGo, setPaginaAtualGo] = useState(1);
const [paginaAtual, setPaginaAtual] = useState(1);
const [alertVisible, setAlertVisible] = useState(false); // Estado para controlar o alerta
const inputRef = useRef(null); // Ref para o input

useEffect(() => {
  if (inputRef.current) {
    inputRef.current.focus(); // Foca o input ao carregar a página
  }
}, []);
useEffect(() => {
  if (inputRef.current) {
    inputRef.current.focus(); // Foca o input ao carregar a página
  }
}, [alertVisible]);

const showAlert = (message) => {
  setResponseMessage(message);
  setAlertVisible(true);
  setTimeout(() => {
    setAlertVisible(false);
  }, 3000); // O alerta será exibido por 3 segundos
};

useEffect(() => {
  setPaginaAtualGo(paginaAtual)
}, [paginaAtual])


const mudarPagina = (novaPagina) => {
  setPaginaAtual(novaPagina);
  console.log(`Página atual: ${novaPagina}`);
};

const handleSubmitSaida = async () => {
  // Log dos dados que estão sendo enviados
  console.log({
    artigo,
    nome,
    infopen,
    cela,
    dataAtual,
    horaAtual,
    imgURL,
    tipo
  });

  try {
    const response = await axios({
      method: 'post',
      url: 'https://alertadiarioes.com/apipevv3/registro_saida_laboral.php',
      data: {
        artigo,
        nome,
        infopen,
        cela,
        dataAtual,
        horaAtual,
        imgURL,
        tipo:'Saída'
      },
      headers: {
        'Content-Type': 'application/json' // Garantindo que os dados estão sendo enviados como JSON
      }
    });

    // Exibir a resposta da API no console
    console.log('Resposta da API:', response.data);
    setResponseMessage('Registro feito com sucesso!');
    setArtigo('');
        setNome('');
        setInfopen('');
        setImgURL('');
        setCela('');
        setSelecao('');
        setInputInfopen('');
        showAlert('Registro de SAÍDA feito com sucesso!');

        inputRef.current.focus();


  } catch (error) {
    // Tratamento de erro detalhado
    if (error.response) {
      // O servidor respondeu com um código de erro
      console.error('Erro na resposta da API:', error.response.data);
      console.error('Status:', error.response.status);
      console.error('Cabeçalhos:', error.response.headers);
    } else if (error.request) {
      // A requisição foi feita, mas nenhuma resposta foi recebida
      console.error('Nenhuma resposta recebida:', error.request);
    } else {
      // Algo deu errado na configuração da requisição
      console.error('Erro ao configurar a requisição:', error.message);
    }
    
    setResponseMessage('Erro ao registrar os dados.');
  }
};
const handleSubmitEntrada = async () => {
  // Log dos dados que estão sendo enviados
  console.log({
    artigo,
    nome,
    infopen,
    cela,
    dataAtual,
    horaAtual,
    imgURL,
    tipo
  });

  try {
    const response = await axios({
      method: 'post',
      url: 'https://alertadiarioes.com/apipevv3/registro_saida_laboral.php',
      data: {
        artigo,
        nome,
        infopen,
        cela,
        dataAtual,
        horaAtual,
        imgURL,
        tipo:'Retorno'
      },
      headers: {
        'Content-Type': 'application/json' // Garantindo que os dados estão sendo enviados como JSON
      }
    });

    // Exibir a resposta da API no console
    console.log('Resposta da API:', response.data);
    setResponseMessage('Registro feito com sucesso!');
    setArtigo('');
        setNome('');
        setInfopen('');
        setImgURL('');
        setCela('');
        setSelecao('');
        setInputInfopen('');
        inputRef.current.focus();
        showAlert('Registro de ENTRADA feito com sucesso!');


  } catch (error) {
    // Tratamento de erro detalhado
    if (error.response) {
      // O servidor respondeu com um código de erro
      console.error('Erro na resposta da API:', error.response.data);
      console.error('Status:', error.response.status);
      console.error('Cabeçalhos:', error.response.headers);
    } else if (error.request) {
      // A requisição foi feita, mas nenhuma resposta foi recebida
      console.error('Nenhuma resposta recebida:', error.request);
    } else {
      // Algo deu errado na configuração da requisição
      console.error('Erro ao configurar a requisição:', error.message);
    }
    
    setResponseMessage('Erro ao registrar os dados.');
  }
};


  useEffect(() => {
    // Função para obter a data e hora local
    const obterDataHora = () => {
      const now = new Date();
      
      // Obter data no formato dd/mm/aaaa
      const data = now.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      
      // Obter hora no formato hh:mm:ss
      const hora = now.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      setDataAtual(data);
      setHoraAtual(hora);
    };

    obterDataHora();

    // Atualiza a hora a cada segundo
    const intervalId = setInterval(obterDataHora, 1000);

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (responseMessage) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Animação suave ao rolar para o topo
      });
    }
  }, [responseMessage]);

  useEffect(() => {
    // Montar a URL com o parâmetro infopen
    if (!inputInfopen && inputInfopen == 0) return; // Não faça a solicitação se inputInfopen não estiver definido

    axios.get(`https://alertadiarioes.com/apipevv3/get_infopen_laboral.php?infopen=${inputInfopen}`)
        .then(response => {
            // Verificar se a resposta tem dados
            if (response.data && response.data.length > 0) {
                const primeiroObjeto = response.data[0];
                const { artigo, nome, selectDate, infopen, visita, imgURL, origem, cela, selecao } = primeiroObjeto;
                setArtigo(artigo || '');
                setNome(nome || '');
                setInfopen(infopen || '');
                setImgURL(imgURL || '');
                setCela(cela || '');
                setSelecao(selecao || '');
                console.log('nome:' + nome);
                
            } else {
                console.warn('Nenhum dado encontrado para o parâmetro fornecido.');
                setArtigo('');
                setNome('');
                setInfopen('');
                setImgURL('');
                setCela('');
                setSelecao(selecao || '');            }
        })
        .catch(error => {
            // Manipule os erros, se houver
            setError('Erro ao buscar dados da API: ' + error.message);
            console.error('Erro ao enviar dados para a API:', error);
        });
}, [inputInfopen]); // O useEffect será chamado sempre que inputInfopen mudar




  




  

  

  return (
    <div>
      {alertVisible && (
  <div style={{
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000, // Para garantir que fique sobre outros elementos
    width: '500px', // Largura fixa para o alerta
  }}>
    <Alert
      severity={responseMessage.includes('sucesso') ? 'success' : 'error'} // Sucesso ou erro baseado na mensagem
      onClose={() => setResponseMessage('')} // Fechar o alerta
    >
      {responseMessage}
    </Alert>
  </div>
)}
     
      <center>
      <div style={{ marginTop: 20, fontFamily: 'policiapenal' }}><center> <p style={{ fontFamily: 'policiapenal', padding: 10, }}><div style={styles.fixedContainer}>
  <button
    onClick={() => mudarPagina(1)}
    style={{ ...styles.botao, backgroundColor: paginaAtual === 1 ? '#253221' : 'gray' }}
  >
    REGISTRAR
  </button>

  <button
    onClick={() => mudarPagina(2)}
    style={{ ...styles.botao, backgroundColor: paginaAtual === 2 ? '#253221' : 'gray' }}
  >
    PRESOS NA RUA
  </button>
  
  <button
    onClick={() => mudarPagina(3)}
    style={{ ...styles.botao, backgroundColor: paginaAtual === 3 ? '#253221' : 'gray' }}
  >
    TODOS
  </button>
</div> </p><br></br> </center></div>

      

 {paginaAtual == 1 &&  <div style={{ marginBottom: "20px", marginTop: 30 }}>
  
 <br></br>
  
  
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: -60 , backgroundColor:'#f9f9f961', width: "700px", height:'100px', borderRadius:'15px'}}>
 <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
    <center><h3 style={{marginTop:'10px'}}><b>INFOPEN</b></h3></center>
    
    <Input
    autoFocus 
      ref={inputRef} // Associe a ref ao input
      type="text"
      placeholder="Digite o Infopen"
      value={inputInfopen}
      onChange={(e) => {
        const valor = e.target.value;
        // Permitir apenas números
        if (/^\d*$/.test(valor)) {
          setInputInfopen(valor);
        }
      }}
      style={{
        width: "300px",
        padding: "10px",
        fontSize: "16px",
        border: "2px solid #545f34",
        borderRadius: "8px",
      }}
    />
    <button
      style={{
        backgroundColor: '#4f4a4a', /* Cor de fundo para o botão RETORNO */
        color: 'white', /* Cor do texto */
        border: 'none', /* Remove a borda padrão */
        padding: '10px 20px', /* Padding interno do botão */
        borderRadius: '8px', /* Borda arredondada */
        cursor: 'pointer', /* Cursor para mostrar que é clicável */
        fontSize: '16px', /* Tamanho da fonte */
        textAlign: 'center',
        width: '150px', // Tamanho do botão
      }}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'green'} /* Cor de hover */
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#4f4a4a'}
      onClick={() => {
        setArtigo('');
        setNome('');
        setInfopen('');
        setImgURL('');
        setCela('');
        setSelecao('');
        setInputInfopen('');
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Animação suave ao rolar para o topo
        });
      }}
    >
      LIMPAR
    </button>
  </div>
</div>

<br></br>
</div>}
{!nome && paginaAtual == 1 && <div style={{height:'300px'}}></div>}
      {nome && paginaAtual == 1 &&  <div style={{
  backgroundColor: '#f9f9f9',
  width: '100%',
  maxWidth: '850px',
  padding: '10px',
  margin: '20px auto',
  borderRadius: '15px',
  boxShadow: '0 6px 18px rgba(0, 0, 0, 0.1)',
  fontSize: '16px',
  color: '#333',
  fontFamily: 'Arial, sans-serif',
  textAlign: 'left',
  border: '1px solid #ddd',
  display: 'flex', // Usa flexbox para organizar as colunas
  flexDirection: 'row',
  justifyContent: 'space-between' // Garantir espaçamento uniforme entre as colunas
}}>
  <div style={{ textAlign:'right', marginRight:'8px', alignContent:'center' }}> {/* Primeira coluna para os rótulos */}
  {nome && paginaAtual == 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
           <button
  style={{
    backgroundColor: '#d9534f', /* Cor de fundo para o botão SAÍDA */
    color: 'white', /* Cor do texto */
    border: 'none', /* Remove a borda padrão */
    padding: '10px 20px', /* Padding interno do botão */
    borderRadius: '8px', /* Borda arredondada */
    cursor: 'pointer', /* Cursor para mostrar que é clicável */
    fontSize: '16px', /* Tamanho da fonte */
    transition: 'background-color 0.3s ease, transform 0.2s', /* Transição suave de cor ao hover e leve animação */
    marginRight: '30px',
    width: '40%',
    display: 'flex', /* Flexbox para centralizar */
    justifyContent: 'center', /* Centraliza horizontalmente */
    alignItems: 'center', /* Centraliza verticalmente */
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' /* Sombra leve */
  }}
  onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#c9302c'} /* Cor de hover */
  onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#d9534f'}
  onClick={() => handleSubmitSaida()}
>
  SAÍDA
</button>

<button
  style={{
    backgroundColor: 'green', /* Cor de fundo para o botão RETORNO */
    color: 'white', /* Cor do texto */
    border: 'none', /* Remove a borda padrão */
    padding: '10px 20px', /* Padding interno do botão */
    borderRadius: '8px', /* Borda arredondada */
    cursor: 'pointer', /* Cursor para mostrar que é clicável */
    fontSize: '16px', /* Tamanho da fonte */
    width: '40%',
    display: 'flex', /* Flexbox para centralizar */
    justifyContent: 'center', /* Centraliza horizontalmente */
    alignItems: 'center', /* Centraliza verticalmente */
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', /* Sombra leve */
    transition: 'background-color 0.3s ease, transform 0.2s' /* Transição suave de cor ao hover e leve animação */
  }}
  onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#253221'} /* Cor de hover */
  onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'green'}
  onClick={() => handleSubmitEntrada()}
>
  RETORNO
</button>

          
          
          </div>}  {imgURL ? (
              <center><img src={imgURL} alt='PPES' height={380} /><center><p style={{ fontSize: 18, color: '#8a9175', padding:'10px' }}></p></center></center>
            ) : (
              <div><center> <br></br><img alt='PPES' height={350} style={{ padding:'10px',border: '1px solid #253221', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} src={userDef}></img></center><center><br></br><p style={{ fontSize: 18, color: '#253221' }}></p></center></div>
            )}  
  </div>
  
  {/* Primeira coluna para os rótulos */}
  

  {/* Segunda coluna para os inputs/valores */}
  {nome && (
  <div style={{ flex: 1, textAlign: 'left', padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', margin: 'auto' }}>
    <div style={{ marginBottom: '15px', color: '#555' }}>
      <label htmlFor="obs" style={{ color: '#006a88', fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Obs:</label>
      <input id="obs" value={artigo} style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '6px', fontSize: '16px' }} />
    </div>
    <div style={{ marginBottom: '15px', color: '#555' }}>
      <label htmlFor="nome" style={{ color: '#006a88', fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Nome:</label>
      <input id="nome" value={nome} style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '6px', fontSize: '16px' }} readOnly />
    </div>
    <div style={{ marginBottom: '15px', color: '#555' }}>
      <label htmlFor="infopen" style={{ color: '#006a88', fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Infopen:</label>
      <input id="infopen" value={infopen} style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '6px', fontSize: '16px' }} readOnly />
    </div>
    <div style={{ marginBottom: '15px', color: '#555' }}>
      <label htmlFor="cela" style={{ color: '#006a88', fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Cela:</label>
      <input id="cela" value={cela} style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '6px', fontSize: '16px' }} readOnly />
    </div>
    <div style={{ marginBottom: '15px', color: '#555' }}>
      <label htmlFor="data" style={{ color: '#006a88', fontWeight: 'bold', marginBottom: '5px', display: 'block' }}>Data:</label>
      <input id="data" value={dataAtual + " às " + horaAtual} style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '6px', fontSize: '16px' }} readOnly />
    </div>
   
  </div>
)}

</div>
}



       
{paginaAtual == 1 && <div style={{height:'200px'}}> </div>}
{paginaAtual == 2 && <ListaLaboral paginaAtual={paginaAtualGo}></ListaLaboral>}
{paginaAtual == 3 && <ListaLaboral paginaAtual={paginaAtualGo}></ListaLaboral>}



      </center>
    </div>
  )
}

const styles = {
  
    fixedContainer: {
     width:'40%',
      display: 'flex',
      flexDirection: 'row', // Para colocar os botões em uma coluna
      gap: '10px', // Espaço entre os botões
      zIndex: 1000, // Garante que os botões fiquem sobre outros elementos
      backgroundColor: '#f9f9f9', // Cor de fundo da caixa
      padding: '10px',
      borderRadius: '8px', // Canto arredondado
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Sombra
    },
   
 
  
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px',
    marginBottom:'10px',
    flexDirection:'row',
    height:'100%'
  },
  botao: {
    margin: '0 5px',
    padding: '10px 20px',
    backgroundColor: 'black',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width:'100%',
    marginRight:'30PX'
  },
};

export default Movimento;
