// import bootstrap - componentes
import axios from 'axios';
import { Button } from 'reactstrap';
import { Input } from 'reactstrap';
import { Form } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../../firebase/config';
import { useDropzone } from 'react-dropzone';
import userDef from '../../assets/img/User-Default3.jpg'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Loading from '../../components/Loading';


const Cadastro = (props) => {
  const navigate = useNavigate();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imgURL, setImgURL] = useState("")
  const [startDate] = useState(new Date());
  const [nome, setNome] = useState("")
  const [cela, setCela] = useState("")
  const [origem, setOrigem] = useState("")
  const [infopen, setInfopen] = useState("")
  const [artigo, setArtigo] = useState("")
  const [selectValue, setSelectValue] = useState('A01');
  const [selectDate, setSelectDate] = useState('');
  const [usuarioLogado, setUsuarioLogado] = useState('');
  const [selecao, setSelecao] = useState("");
  const [visita, setVisita] = useState("");
  const [botaoClicado, setBotaoClicado] = useState(false);
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsuarioLogado((user.email).toUpperCase());
      } else {
        setUsuarioLogado('');
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);


  const handleSelecao = (event) => {
    setSelecao(event.target.value);
  };
  const handleSelecaoVisita = (event) => {
    setVisita(event.target.value);
  };

  const handleDrop = (acceptedFiles) => {
    // Acessar o arquivo de imagem carregado
    const file = acceptedFiles[0];

    // Criar uma URL temporária para exibição da imagem
    const imageUrl = URL.createObjectURL(file);


    // Atualizar o estado com a imagem carregada
    setUploadedImage(imageUrl);

    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result1 = ' ';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }


    const storageRef = ref(storage, `images/${result1 + file.name}`)


    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      "state_changed",
      snapshot => {



      },

      error => {

        alert(error)
      },

      () => {

        getDownloadURL(uploadTask.snapshot.ref).then(url => {

          setImgURL(url)



        })
      }


    )
  };





  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: handleDrop
  });



  //listagem dos campos do select para cela
  const list = [

    // QUANDRANTE A101 A A112
    { id: 'A01', name: 'A01' },
    { id: 'A02', name: 'A02' },
    { id: 'A03', name: 'A03' },
    { id: 'A04', name: 'A04' },
    { id: 'A05', name: 'A05' },
    { id: 'A06', name: 'A06' },
    { id: 'A07', name: 'A07' },
    { id: 'A08', name: 'A08' },
    { id: 'A09', name: 'A09' },
    { id: 'A10', name: 'A10' },
    { id: 'A11', name: 'A11' },
    { id: 'A12', name: 'A12' },





    // GALERIA CRAVO


    // QUANDRANTE A101 A A112
    { id: 'B00', name: 'B00' },
    { id: 'B01', name: 'B01' },
    { id: 'B02', name: 'B02' },
    { id: 'B03', name: 'B03' },
    { id: 'B04', name: 'B04' },
    { id: 'B05', name: 'B05' },
    { id: 'B06', name: 'B06' },
    { id: 'B07', name: 'B07' },
    { id: 'B08', name: 'B08' },
    { id: 'B09', name: 'B09' },
    { id: 'B10', name: 'B10' },
    { id: 'B11', name: 'B11' },
    { id: 'B12', name: 'B12' },
    { id: 'B13', name: 'B13' },
    { id: 'B14', name: 'B14' },
    { id: 'B15', name: 'B15' },

    // GALERIA CHARLIE 
    // QUANDRANTE A101 A A112
    { id: 'C00', name: 'C00' },
    { id: 'C01', name: 'C01' },
    { id: 'C02', name: 'C02' },
    { id: 'C03', name: 'C03' },
    { id: 'C04', name: 'C04' },
    { id: 'C05', name: 'C05' },
    { id: 'C06', name: 'C06' },
    { id: 'C07', name: 'C07' },
    { id: 'C08', name: 'C08' },
    { id: 'C09', name: 'C09' },
    { id: 'C10', name: 'C10' },
    { id: 'C11', name: 'C11' },
    { id: 'C12', name: 'C12' },
    // QUCNDRCNTE C13 C C22
    { id: 'C13', name: 'C13' },
    { id: 'C14', name: 'C14' },
    { id: 'C15', name: 'C15' },
    { id: 'C16', name: 'C16' },
    { id: 'C17', name: 'C17' },


    { id: 'D00', name: 'D00' },
    { id: 'D01', name: 'D01' },
    { id: 'D02', name: 'D02' },
    { id: 'D03', name: 'D03' },
    { id: 'D04', name: 'D04' },
    { id: 'D05', name: 'D05' },
    { id: 'D06', name: 'D06' },
    { id: 'D07', name: 'D07' },
    { id: 'D08', name: 'D08' },
    { id: 'D09', name: 'D09' },
    { id: 'D10', name: 'D10' },
    { id: 'D11', name: 'D11' },
    { id: 'D12', name: 'D12' },
    { id: 'D13', name: 'D13' },
    { id: 'D14', name: 'D14' },
    { id: 'D15', name: 'D15' },

    // GALERIA DELTA



    { id: 'E00', name: 'E00' },
    { id: 'E01', name: 'E01' },
    { id: 'E02', name: 'E02' },
    { id: 'E03', name: 'E03' },
    { id: 'E04', name: 'E04' },
    { id: 'E05', name: 'E05' },
    { id: 'E06', name: 'E06' },
    { id: 'E07', name: 'E07' },
    { id: 'E08', name: 'E08' },
    { id: 'E09', name: 'E09' },
    { id: 'E10', name: 'E10' },
    { id: 'E11', name: 'E11' },
    { id: 'E12', name: 'E12' },
    // QUENDRENTE E13 E E22
    { id: 'E13', name: 'E13' },
    { id: 'E14', name: 'E14' },
    { id: 'E15', name: 'E15' },
    { id: 'E16', name: 'E16' },
    { id: 'E17', name: 'E17' },


    //triagem 
    { id: 'TR01', name: 'TR01' },
    { id: 'TR02', name: 'TR02' },
    { id: 'TR03', name: 'TR03' },
    { id: 'TR04', name: 'TR04' },
  
    { id: 'ISOLAMENTO01', name: 'ISOLAMENTO01' },
    { id: 'ISOLAMENTO02', name: 'ISOLAMENTO02' },
    { id: 'ISOLAMENTO03', name: 'ISOLAMENTO03' },
    { id: 'ISOLAMENTO04', name: 'ISOLAMENTO04' },
    { id: 'ISOLAMENTO05', name: 'ISOLAMENTO05' },
    { id: 'ISOLAMENTO06', name: 'ISOLAMENTO06' },
    { id: 'ISOLAMENTO07', name: 'ISOLAMENTO07' },
    { id: 'ISOLAMENTO08', name: 'ISOLAMENTO08' },
    { id: 'ISOLAMENTO09', name: 'ISOLAMENTO09' },
    { id: 'ISOLAMENTO10', name: 'ISOLAMENTO10' },

    //fora da unidade 
    { id: 'DOMICILIAR', name: 'DOMICILIAR' },
    { id: 'HOSPITAL', name: 'HOSPITAL' },
    { id: 'PERNOITE', name: 'PERNOITE' },

  ];
  //fim da listagem de select




  useEffect(() => {
    setCela(selectValue)


  }, [selectValue])



  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    setBotaoClicado(true);

    const dataAtual = new Date();
    const horaAtual = dataAtual.getHours();
    const minutoAtual = dataAtual.getMinutes();
    var dataSobe = selectDate + ` ${String(horaAtual).padStart(2, '0')}:${String(minutoAtual).padStart(2, '0')}`
    const dados = {
      nome: nome,
      cela: cela,
      dataEntrada: selectDate,
      infopen: infopen,
      origem: origem,
      dataRegistro: dataSobe,
      usuario: usuarioLogado,
      tipo: 'ENTRADA',

    };

    axios.post('https://alertadiarioes.com/apipevv3/post_movimento.php', dados)
      .then(() => {
      })
      .catch((error) => {
        console.error('Erro ao enviar dados para a API:', error);
      });

    const data = {

      nome,
      cela,
      origem,
      infopen,
      selectDate,
      artigo,
      imgURL,
      selecao,
      visita
    };

    async function enviarDadosParaAPI(data) {
      const url = 'https://alertadiarioes.com/apipevv3/insert_id_galerias_cdpg.php';

      try {
        // Envia uma requisição POST para a API com os dados
        const response = await axios.post(url, data);
        console.log(response)
      } catch (error) {
        console.error('Erro ao enviar dados para a API:', error);
      }
    }
    // Chama a função para enviar dados para a API
    await enviarDadosParaAPI(data);



    setArtigo('')
    setNome('')
    setInfopen('')
    setArtigo('')
    setImgURL('')
    setOrigem('')
    setUploadedImage('')
    setVisita('')
    setSelecao('')
    window.scrollTo(0, 0);

    setBotaoClicado(false);
    navigate('/')
    window.location.reload()



  }



  useEffect(() => {
    if (startDate) {
      const dia = startDate.getDate().toString().padStart(2, '0');
      const mes = (startDate.getMonth() + 1).toString().padStart(2, '0');
      const ano = startDate.getFullYear();
      setSelectDate(`${dia}/${mes}/${ano}`);
    }
  }, [startDate]);


  return (<>


    <center>
      <div style={{ color: 'black', borderRadius: 10, marginTop: '40px', backgroundColor: 'none', width: '70%', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginBottom: 60 }}>
        <div style={{ marginTop: 60, fontFamily: 'policiapenal' }}><center> <p style={{ fontFamily: 'policiapenal', padding: 10, }}><h1>REGISTRO DE ENTRADAS</h1><h5>CADASTRO DE NOVOS PRESOS NA CONTAGEM</h5> </p><br></br> </center></div>
        {loading && <Loading></Loading>}
        {!loading && <Form onSubmit={handleSubmit}>

          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {uploadedImage ? (
              <center><img src={uploadedImage} alt='PPES' height={250} /><center><p style={{ fontSize: 18, color: '#8a9175' }}>Imagem carregada com sucesso.</p></center></center>
            ) : (
              <div><center> <br></br><img alt='PPES' style={{ border: '1px solid #253221', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} src={userDef}></img></center><center><br></br><p style={{ fontSize: 18, color: '#253221' }}>Arraste e solte uma imagem no quadro ou clique para selecionar o arquivo no seu computador.</p></center></div>
            )}
          </div><br></br>

          <FormGroup>

            <Label for="exampleEmail">
              <p style={{ color: '#8a9175', marginBottom: -3, }}><b>NOME*</b></p>
            </Label>
            <Input
              id="nome"
              name="nome"
              placeholder="Nome completo"
              type="text"
              required
              value={nome}
              style={{ border: '2px solid #253221' }}
              onChange={(e) => { setNome(e.target.value.toUpperCase()) }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="examplePassword">
              <p style={{ color: '#8a9175', marginBottom: -3 }}><b>ORIGEM*</b></p>
            </Label>
            <Input
              id="origem"
              name="origem"
              placeholder="Origem do recebimento"
              type="text"
              required
              value={origem}
              style={{ border: '2px solid #253221' }}
              onChange={(e) => { setOrigem(e.target.value.toUpperCase()) }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleSelect">
              <p style={{ color: '#8a9175', marginBottom: -3 }}><b>CELA DE DESTINO*</b></p>
            </Label>
            <Input
              id="exampleSelect"
              name="select"
              style={{ border: '2px solid #253221' }}
              type="select"
              required
              value={selectValue} onChange={e => setSelectValue(e.target.value)}
            >
              {list.map((item, index) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup >
            <Label for="exampleSelectMulti">
              <p style={{ color: '#8a9175', marginBottom: -3 }}><b>DATA DA ENTRADA*</b></p>
            </Label>

            <Input
              id="origem"
              name="origem"
              placeholder="Origem do recebimento"

              required
              value={selectDate}
              style={{ border: '2px solid #253221' }}
              onChange={(e) => setSelectDate(e.target.value)}
            />




          </FormGroup>
          <br></br>
          <center><small><b style={{ color: '#8a9175' }}>INFORMAÇÕES COMPLEMENTARES</b></small></center><br></br>
          <FormGroup>
            <Label for="exampleFile">

            </Label>
            <FormGroup>
              <Label for="examplePassword">
                <p style={{ color: '#8a9175', marginBottom: -3, }}><b>INFOPEN</b></p>
              </Label>
              <Input
                id="infopen"
                name="infopen"
                placeholder="Número do INFOPEN"
                type="number"
                style={{ border: '2px solid #253221' }}
                value={infopen}
                onChange={(e) => { setInfopen(e.target.value.toUpperCase()) }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">
                <p style={{ color: '#8a9175', marginBottom: -3, }}> <b>OBSERVAÇÃO</b></p>
              </Label>
              <Input
                id="artigo"
                name="artigo"
                placeholder="Artigo"
                type="text"
                value={artigo}
                style={{ border: '2px solid #253221' }}
                onChange={(e) => { setArtigo(e.target.value.toUpperCase()) }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">
                <p style={{ color: '#8a9175', marginBottom: -3, }}> <b>TIPO DE CRIME</b></p>
              </Label>
              <Input style={{ border: '2px solid #253221' }} type="select" name="tipoCrime" id="tipoCrimeSelect" value={selecao} onChange={handleSelecao}>
                <option value="">Selecione</option>
                <option value="PRIM.">PRIMÁRIO</option>
                <option value="REIN.">REINCIDENTE</option>
              </Input>
            </FormGroup>



          </FormGroup>
          <br></br>
          <center><Button disabled={botaoClicado}
            style={{ backgroundColor: '#8a9175', color: 'white', fontFamily: 'policiapenal', fontSize: 14, padding: 14, marginBottom: 30 }}>CADASTRAR</Button></center>
        </Form>}</div></center></>
  )
}

export default Cadastro