import { useEffect, useState } from 'react';
import styles from './Contagem.module.css'
import Celacontagem from '../../components/Celacontagem'
import { Button, Table } from 'reactstrap';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FiPrinter } from "react-icons/fi";
import logo from '../../assets/img/logo.png';
import excel from '../../assets/img/excel.png';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";


const Contagem = ({ galerias, atualizarDadosMovimento2 }) => {

  const [pagina, setPagina] = useState(6)
  const [ativaBotao, setAtivaBotao] = useState(6)
  const data = new Date();
  const dia = String(data.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se for necessário
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero, então adicionamos 1
  const ano = data.getFullYear();
  const agora = new Date();
  const hora = agora.getHours();
  const minutos = agora.getMinutes();
  const minutosFormatados = minutos.toString().padStart(2, '0');
  const [pessoasHospital, setPessoasHospital] = useState('')
  const [pessoasPernoite, setPessoasPernoite] = useState('')
  const [pessoasDomiciliar, setPessoasDomiciliar] = useState('')
  const [pessoasDomiciliartotal, setPessoasDomiciliartotal] = useState('')

  useEffect(() => {
    atualizarDadosMovimento2()
  }, [])



  const exportToExcel = () => {
    // Função para extrair apenas os números da cela
    const extrairNumerosCela = (cela) => {
      if (!cela) return ''; // Se a cela for vazia, retorna uma string vazia
      return cela.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    };

    // Função para determinar a galeria com base na cela
    const getGaleria = (cela) => {
      // Verifica se a cela não está vazia
      if (cela && cela.trim() !== '') {
        const duasPrimeirasLetras = cela.substring(0, 2).toUpperCase();
        switch (duasPrimeirasLetras) {
          case 'DO':
            return 'P._DOMICILIAR';
          case 'AL':
            return 'ALOJAMENTO';
          case 'A0':
            return 'ALFA';
          case 'A1':
            return 'ALFA';
          case 'A2':
            return 'ALFA';
          case 'B0':
            return 'BRAVO';
          case 'B1':
            return 'BRAVO';
          case 'B2':
            return 'BRAVO';
          case 'C0':
            return 'CHARLIE';
          case 'C1':
            return 'CHARLIE';
          case 'C2':
            return 'CHARLIE';
          case 'E0':
            return 'ECHO';
          case 'E1':
            return 'ECHO';
          case 'E2':
            return 'ECHO';
          case 'F0':
            return 'FOX';
          case 'F1':
            return 'FOX';
          case 'F2':
            return 'FOX';
          case 'H0':
            return 'HOTEL';
          case 'H1':
            return 'HOTEL';
          case 'H2':
            return 'HOTEL';
          case 'G0':
            return 'GOLF';
          case 'G1':
            return 'GOLF';
          case 'G2':
            return 'GOLF';
          case 'D0':
            return 'DELTA';
          case 'D1':
            return 'DELTA';
          case 'TR':
            return 'TRIAGEM';
          case 'HO':
            return 'INT._HOSPITALAR';
          case 'IS':
            return 'ISOLAMENTO';
          case 'HE':
            return 'HOTEL';
          case 'PE':
            return 'PERNOITE';
          case 'PE':
            return 'PERNOITE';
          default:
            return ''; // Retorna uma string vazia se não corresponder a nenhum caso específico
        }
      } else {
        return ''; // Retorna uma string vazia se a cela estiver vazia
      }
    };

    // Filtra as galerias com cela não vazia e correspondente aos casos específicos
    const galeriasFiltradas = galerias.filter(item => {
      if (!item.cela || item.cela.trim() === '') return false; // Se a cela estiver vazia, não exporta
      const duasPrimeirasLetras = item.cela.substring(0, 2).toUpperCase();
      return ['AL', 'A0', 'A1', 'A2', 'B0', 'B1', 'B2', 'C0', 'C1', 'C2', 'D0', 'D1', 'TR', 'HO', 'IS', 'HE', 'PE', 'DO', 'E0', 'E1', 'E2', 'F0', 'F1', 'F2', 'G0', 'G1', 'G2'].includes(duasPrimeirasLetras); // Exporta apenas se as duas primeiras letras da cela corresponderem a essas letras
    });

    // Ordena os dados pelo campo "cela"
    const sortedData = galeriasFiltradas
      .sort((a, b) => (a.cela < b.cela ? -1 : 1))
      .map((item) => {
        // Extrai apenas os números da cela
        const celaNumerica = extrairNumerosCela(item.cela);
        // Determina a galeria com base na cela
        const galeria = getGaleria(item.cela);
        return { galeria, ...item };
      });

    // Define a ordem das colunas no Excel
    // Função para remover todas as letras de uma string
    const removerLetras = (str) => {
      if (!str) return ''; // Retorna uma string vazia se o parâmetro for nulo ou vazio
      return str.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
    };

    // Define a ordem das colunas no Excel
    const columnsOrder = ["infopen", "galeria", "cela", "nome", "origem", "selectDate", "entradaGaleria", "artigo", "selecao"];

    // Mapeia os dados para garantir a ordem das colunas e remover letras da cela
    const orderedData = sortedData.map(item => {
      const orderedItem = {};
      columnsOrder.forEach(column => {
        // Se a coluna for "cela", remove todas as letras antes de atribuir ao novo objeto
        if (column === "cela") {
          orderedItem[column] = removerLetras(item[column]);
        } else {
          orderedItem[column] = item[column];
        }
      });
      return orderedItem;
    });


    // Converte os dados para uma planilha Excel
    const ws = XLSX.utils.json_to_sheet(orderedData);

    // Cria o workbook
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    // Escreve o arquivo Excel
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Cria um Blob para o arquivo Excel
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    // Salva o arquivo Excel
    FileSaver.saveAs(dataBlob, `Contagem-${dia}-${mes}-${ano}.xlsx`);
  };



  const imprimirConteudo = () => {
    const conteudoParaImpressao = document.querySelector('.conteudo-para-impressao');
    if (conteudoParaImpressao) {
      window.print(conteudoParaImpressao);
    }
  };


  const [pessoasSearchMapA01, setPessoasSearchMapA01] = useState('')
  const [pessoasSearchMapA02, setPessoasSearchMapA02] = useState('')
  const [pessoasSearchMapA03, setPessoasSearchMapA03] = useState('')
  const [pessoasSearchMapA04, setPessoasSearchMapA04] = useState('')
  const [pessoasSearchMapA05, setPessoasSearchMapA05] = useState('')
  const [pessoasSearchMapA06, setPessoasSearchMapA06] = useState('')
  const [pessoasSearchMapA07, setPessoasSearchMapA07] = useState('')
  const [pessoasSearchMapA08, setPessoasSearchMapA08] = useState('')
  const [pessoasSearchMapA09, setPessoasSearchMapA09] = useState('')
  const [pessoasSearchMapA10, setPessoasSearchMapA10] = useState('')
  const [pessoasSearchMapA11, setPessoasSearchMapA11] = useState('')
  const [pessoasSearchMapA12, setPessoasSearchMapA12] = useState('')



  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB00, setPessoasSearchMapB00] = useState('')
  const [pessoasSearchMapB01, setPessoasSearchMapB01] = useState('')
  const [pessoasSearchMapB02, setPessoasSearchMapB02] = useState('')
  const [pessoasSearchMapB03, setPessoasSearchMapB03] = useState('')
  const [pessoasSearchMapB04, setPessoasSearchMapB04] = useState('')
  const [pessoasSearchMapB05, setPessoasSearchMapB05] = useState('')
  const [pessoasSearchMapB06, setPessoasSearchMapB06] = useState('')
  const [pessoasSearchMapB07, setPessoasSearchMapB07] = useState('')
  const [pessoasSearchMapB08, setPessoasSearchMapB08] = useState('')
  const [pessoasSearchMapB09, setPessoasSearchMapB09] = useState('')
  const [pessoasSearchMapB10, setPessoasSearchMapB10] = useState('')
  const [pessoasSearchMapB11, setPessoasSearchMapB11] = useState('')
  const [pessoasSearchMapB12, setPessoasSearchMapB12] = useState('')
  const [pessoasSearchMapB13, setPessoasSearchMapB13] = useState('')
  const [pessoasSearchMapB14, setPessoasSearchMapB14] = useState('')
  const [pessoasSearchMapB15, setPessoasSearchMapB15] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC00, setPessoasSearchMapC00] = useState('')
  const [pessoasSearchMapC01, setPessoasSearchMapC01] = useState('')
  const [pessoasSearchMapC02, setPessoasSearchMapC02] = useState('')
  const [pessoasSearchMapC03, setPessoasSearchMapC03] = useState('')
  const [pessoasSearchMapC04, setPessoasSearchMapC04] = useState('')
  const [pessoasSearchMapC05, setPessoasSearchMapC05] = useState('')
  const [pessoasSearchMapC06, setPessoasSearchMapC06] = useState('')
  const [pessoasSearchMapC07, setPessoasSearchMapC07] = useState('')
  const [pessoasSearchMapC08, setPessoasSearchMapC08] = useState('')
  const [pessoasSearchMapC09, setPessoasSearchMapC09] = useState('')
  const [pessoasSearchMapC10, setPessoasSearchMapC10] = useState('')
  const [pessoasSearchMapC11, setPessoasSearchMapC11] = useState('')
  const [pessoasSearchMapC12, setPessoasSearchMapC12] = useState('')
  const [pessoasSearchMapC13, setPessoasSearchMapC13] = useState('')
  const [pessoasSearchMapC14, setPessoasSearchMapC14] = useState('')
  const [pessoasSearchMapC15, setPessoasSearchMapC15] = useState('')
  const [pessoasSearchMapC16, setPessoasSearchMapC16] = useState('')
  const [pessoasSearchMapC17, setPessoasSearchMapC17] = useState('')



  // CRIAÇÃO DAS VARIAVEIS GALERIA D (DD + DE)
  const [pessoasSearchMapD00, setPessoasSearchMapD00] = useState('')
  const [pessoasSearchMapD01, setPessoasSearchMapD01] = useState('')
  const [pessoasSearchMapD02, setPessoasSearchMapD02] = useState('')
  const [pessoasSearchMapD03, setPessoasSearchMapD03] = useState('')
  const [pessoasSearchMapD04, setPessoasSearchMapD04] = useState('')
  const [pessoasSearchMapD05, setPessoasSearchMapD05] = useState('')
  const [pessoasSearchMapD06, setPessoasSearchMapD06] = useState('')
  const [pessoasSearchMapD07, setPessoasSearchMapD07] = useState('')
  const [pessoasSearchMapD08, setPessoasSearchMapD08] = useState('')
  const [pessoasSearchMapD09, setPessoasSearchMapD09] = useState('')
  const [pessoasSearchMapD10, setPessoasSearchMapD10] = useState('')
  const [pessoasSearchMapD11, setPessoasSearchMapD11] = useState('')
  const [pessoasSearchMapD12, setPessoasSearchMapD12] = useState('')
  const [pessoasSearchMapD13, setPessoasSearchMapD13] = useState('')
  const [pessoasSearchMapD14, setPessoasSearchMapD14] = useState('')
  const [pessoasSearchMapD15, setPessoasSearchMapD15] = useState('')
  const [pessoasSearchMapD16, setPessoasSearchMapD16] = useState('')
  const [pessoasSearchMapD17, setPessoasSearchMapD17] = useState('')



  // GALERIA E

  const [pessoasSearchMapE00, setPessoasSearchMapE00] = useState('')
  const [pessoasSearchMapE01, setPessoasSearchMapE01] = useState('')
  const [pessoasSearchMapE02, setPessoasSearchMapE02] = useState('')
  const [pessoasSearchMapE03, setPessoasSearchMapE03] = useState('')
  const [pessoasSearchMapE04, setPessoasSearchMapE04] = useState('')
  const [pessoasSearchMapE05, setPessoasSearchMapE05] = useState('')
  const [pessoasSearchMapE06, setPessoasSearchMapE06] = useState('')
  const [pessoasSearchMapE07, setPessoasSearchMapE07] = useState('')
  const [pessoasSearchMapE08, setPessoasSearchMapE08] = useState('')
  const [pessoasSearchMapE09, setPessoasSearchMapE09] = useState('')
  const [pessoasSearchMapE10, setPessoasSearchMapE10] = useState('')
  const [pessoasSearchMapE11, setPessoasSearchMapE11] = useState('')
  const [pessoasSearchMapE12, setPessoasSearchMapE12] = useState('')
  const [pessoasSearchMapE13, setPessoasSearchMapE13] = useState('')
  const [pessoasSearchMapE14, setPessoasSearchMapE14] = useState('')
  const [pessoasSearchMapE15, setPessoasSearchMapE15] = useState('')
  const [pessoasSearchMapE16, setPessoasSearchMapE16] = useState('')
  const [pessoasSearchMapE17, setPessoasSearchMapE17] = useState('')


  // GALERIA F

  const [pessoasSearchMapISOLAMENTO01, setPessoasSearchMapISOLAMENTO01] = useState('')
  const [pessoasSearchMapISOLAMENTO02, setPessoasSearchMapISOLAMENTO02] = useState('')
  const [pessoasSearchMapISOLAMENTO03, setPessoasSearchMapISOLAMENTO03] = useState('')
  const [pessoasSearchMapISOLAMENTO04, setPessoasSearchMapISOLAMENTO04] = useState('')
  const [pessoasSearchMapISOLAMENTO05, setPessoasSearchMapISOLAMENTO05] = useState('')
  const [pessoasSearchMapISOLAMENTO06, setPessoasSearchMapISOLAMENTO06] = useState('')
  const [pessoasSearchMapISOLAMENTO07, setPessoasSearchMapISOLAMENTO07] = useState('')
  const [pessoasSearchMapISOLAMENTO08, setPessoasSearchMapISOLAMENTO08] = useState('')
  const [pessoasSearchMapISOLAMENTO09, setPessoasSearchMapISOLAMENTO09] = useState('')
  const [pessoasSearchMapISOLAMENTO10, setPessoasSearchMapISOLAMENTO10] = useState('')


  // GALERIA G


  //variaveis da triagem


  const [pessoasSearchMapTR00, setPessoasSearchMapTR00] = useState('')
  const [pessoasSearchMapTR01, setPessoasSearchMapTR01] = useState('')
  const [pessoasSearchMapTR02, setPessoasSearchMapTR02] = useState('')
  const [pessoasSearchMapTR03, setPessoasSearchMapTR03] = useState('')
  const [pessoasSearchMapTR04, setPessoasSearchMapTR04] = useState('')


  //CRIAÇÃO DAS VARIÁVEIS DE PRESOS FORA DA UNIDADE


  const [pessoasHospitaltotal, setPessoasHospitaltotal] = useState('')
  const [pessoasPernoitetotal, setPessoasPernoitetotal] = useState('')



  // criação das variáveis de totalização do componente Cela
  const [pessoasSearchMapA01total, setPessoasSearchMapA01total] = useState('')
  const [pessoasSearchMapA02total, setPessoasSearchMapA02total] = useState('')
  const [pessoasSearchMapA03total, setPessoasSearchMapA03total] = useState('')
  const [pessoasSearchMapA04total, setPessoasSearchMapA04total] = useState('')
  const [pessoasSearchMapA05total, setPessoasSearchMapA05total] = useState('')
  const [pessoasSearchMapA06total, setPessoasSearchMapA06total] = useState('')
  const [pessoasSearchMapA07total, setPessoasSearchMapA07total] = useState('')
  const [pessoasSearchMapA08total, setPessoasSearchMapA08total] = useState('')
  const [pessoasSearchMapA09total, setPessoasSearchMapA09total] = useState('')
  const [pessoasSearchMapA10total, setPessoasSearchMapA10total] = useState('')
  const [pessoasSearchMapA11total, setPessoasSearchMapA11total] = useState('')
  const [pessoasSearchMapA12total, setPessoasSearchMapA12total] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB00total, setPessoasSearchMapB00total] = useState('')
  const [pessoasSearchMapB01total, setPessoasSearchMapB01total] = useState('')
  const [pessoasSearchMapB02total, setPessoasSearchMapB02total] = useState('')
  const [pessoasSearchMapB03total, setPessoasSearchMapB03total] = useState('')
  const [pessoasSearchMapB04total, setPessoasSearchMapB04total] = useState('')
  const [pessoasSearchMapB05total, setPessoasSearchMapB05total] = useState('')
  const [pessoasSearchMapB06total, setPessoasSearchMapB06total] = useState('')
  const [pessoasSearchMapB07total, setPessoasSearchMapB07total] = useState('')
  const [pessoasSearchMapB08total, setPessoasSearchMapB08total] = useState('')
  const [pessoasSearchMapB09total, setPessoasSearchMapB09total] = useState('')
  const [pessoasSearchMapB10total, setPessoasSearchMapB10total] = useState('')
  const [pessoasSearchMapB11total, setPessoasSearchMapB11total] = useState('')
  const [pessoasSearchMapB12total, setPessoasSearchMapB12total] = useState('')
  const [pessoasSearchMapB13total, setPessoasSearchMapB13total] = useState('')
  const [pessoasSearchMapB14total, setPessoasSearchMapB14total] = useState('')
  const [pessoasSearchMapB15total, setPessoasSearchMapB15total] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC00total, setPessoasSearchMapC00total] = useState('')
  const [pessoasSearchMapC01total, setPessoasSearchMapC01total] = useState('')
  const [pessoasSearchMapC02total, setPessoasSearchMapC02total] = useState('')
  const [pessoasSearchMapC03total, setPessoasSearchMapC03total] = useState('')
  const [pessoasSearchMapC04total, setPessoasSearchMapC04total] = useState('')
  const [pessoasSearchMapC05total, setPessoasSearchMapC05total] = useState('')
  const [pessoasSearchMapC06total, setPessoasSearchMapC06total] = useState('')
  const [pessoasSearchMapC07total, setPessoasSearchMapC07total] = useState('')
  const [pessoasSearchMapC08total, setPessoasSearchMapC08total] = useState('')
  const [pessoasSearchMapC09total, setPessoasSearchMapC09total] = useState('')
  const [pessoasSearchMapC10total, setPessoasSearchMapC10total] = useState('')
  const [pessoasSearchMapC11total, setPessoasSearchMapC11total] = useState('')
  const [pessoasSearchMapC12total, setPessoasSearchMapC12total] = useState('')
  const [pessoasSearchMapC13total, setPessoasSearchMapC13total] = useState('')
  const [pessoasSearchMapC14total, setPessoasSearchMapC14total] = useState('')
  const [pessoasSearchMapC15total, setPessoasSearchMapC15total] = useState('')
  const [pessoasSearchMapC16total, setPessoasSearchMapC16total] = useState('')
  const [pessoasSearchMapC17total, setPessoasSearchMapC17total] = useState('')


  // CRIAÇÃO DAS VARIAVEIS GALERIA D
  const [pessoasSearchMapD00total, setPessoasSearchMapD00total] = useState('')
  const [pessoasSearchMapD01total, setPessoasSearchMapD01total] = useState('')
  const [pessoasSearchMapD02total, setPessoasSearchMapD02total] = useState('')
  const [pessoasSearchMapD03total, setPessoasSearchMapD03total] = useState('')
  const [pessoasSearchMapD04total, setPessoasSearchMapD04total] = useState('')
  const [pessoasSearchMapD05total, setPessoasSearchMapD05total] = useState('')
  const [pessoasSearchMapD06total, setPessoasSearchMapD06total] = useState('')
  const [pessoasSearchMapD07total, setPessoasSearchMapD07total] = useState('')
  const [pessoasSearchMapD08total, setPessoasSearchMapD08total] = useState('')
  const [pessoasSearchMapD09total, setPessoasSearchMapD09total] = useState('')
  const [pessoasSearchMapD10total, setPessoasSearchMapD10total] = useState('')
  const [pessoasSearchMapD11total, setPessoasSearchMapD11total] = useState('')
  const [pessoasSearchMapD12total, setPessoasSearchMapD12total] = useState('')
  const [pessoasSearchMapD13total, setPessoasSearchMapD13total] = useState('')
  const [pessoasSearchMapD14total, setPessoasSearchMapD14total] = useState('')
  const [pessoasSearchMapD15total, setPessoasSearchMapD15total] = useState('')
  const [pessoasSearchMapD16total, setPessoasSearchMapD16total] = useState('')
  const [pessoasSearchMapD17total, setPessoasSearchMapD17total] = useState('')


  //GALERIA E

  const [pessoasSearchMapE00total, setPessoasSearchMapE00total] = useState('')
  const [pessoasSearchMapE01total, setPessoasSearchMapE01total] = useState('')
  const [pessoasSearchMapE02total, setPessoasSearchMapE02total] = useState('')
  const [pessoasSearchMapE03total, setPessoasSearchMapE03total] = useState('')
  const [pessoasSearchMapE04total, setPessoasSearchMapE04total] = useState('')
  const [pessoasSearchMapE05total, setPessoasSearchMapE05total] = useState('')
  const [pessoasSearchMapE06total, setPessoasSearchMapE06total] = useState('')
  const [pessoasSearchMapE07total, setPessoasSearchMapE07total] = useState('')
  const [pessoasSearchMapE08total, setPessoasSearchMapE08total] = useState('')
  const [pessoasSearchMapE09total, setPessoasSearchMapE09total] = useState('')
  const [pessoasSearchMapE10total, setPessoasSearchMapE10total] = useState('')
  const [pessoasSearchMapE11total, setPessoasSearchMapE11total] = useState('')
  const [pessoasSearchMapE12total, setPessoasSearchMapE12total] = useState('')
  const [pessoasSearchMapE13total, setPessoasSearchMapE13total] = useState('')
  const [pessoasSearchMapE14total, setPessoasSearchMapE14total] = useState('')
  const [pessoasSearchMapE15total, setPessoasSearchMapE15total] = useState('')
  const [pessoasSearchMapE16total, setPessoasSearchMapE16total] = useState('')
  const [pessoasSearchMapE17total, setPessoasSearchMapE17total] = useState('')


  const [pessoasSearchMapISOLAMENTO01total, setPessoasSearchMapISOLAMENTO01total] = useState('')
  const [pessoasSearchMapISOLAMENTO02total, setPessoasSearchMapISOLAMENTO02total] = useState('')
  const [pessoasSearchMapISOLAMENTO03total, setPessoasSearchMapISOLAMENTO03total] = useState('')
  const [pessoasSearchMapISOLAMENTO04total, setPessoasSearchMapISOLAMENTO04total] = useState('')
  const [pessoasSearchMapISOLAMENTO05total, setPessoasSearchMapISOLAMENTO05total] = useState('')
  const [pessoasSearchMapISOLAMENTO06total, setPessoasSearchMapISOLAMENTO06total] = useState('')
  const [pessoasSearchMapISOLAMENTO07total, setPessoasSearchMapISOLAMENTO07total] = useState('')
  const [pessoasSearchMapISOLAMENTO08total, setPessoasSearchMapISOLAMENTO08total] = useState('')
  const [pessoasSearchMapISOLAMENTO09total, setPessoasSearchMapISOLAMENTO09total] = useState('')
  const [pessoasSearchMapISOLAMENTO10total, setPessoasSearchMapISOLAMENTO10total] = useState('')




  const [pessoasSearchMapTR00total, setPessoasSearchMapTR00total] = useState('')
  const [pessoasSearchMapTR01total, setPessoasSearchMapTR01total] = useState('')
  const [pessoasSearchMapTR02total, setPessoasSearchMapTR02total] = useState('')
  const [pessoasSearchMapTR03total, setPessoasSearchMapTR03total] = useState('')
  const [pessoasSearchMapTR04total, setPessoasSearchMapTR04total] = useState('')







  // Criação das variáveis toalizadoras de valores
  const totalSomaA =
    pessoasSearchMapA01total +
    pessoasSearchMapA02total +
    pessoasSearchMapA03total +
    pessoasSearchMapA04total +
    pessoasSearchMapA05total +
    pessoasSearchMapA06total +
    pessoasSearchMapA07total +
    pessoasSearchMapA08total +
    pessoasSearchMapA09total +
    pessoasSearchMapA10total +
    pessoasSearchMapA11total +
    pessoasSearchMapA12total


  const totalISOLAMENTO =
    pessoasSearchMapISOLAMENTO01total +
    pessoasSearchMapISOLAMENTO02total +
    pessoasSearchMapISOLAMENTO03total +
    pessoasSearchMapISOLAMENTO04total +
    pessoasSearchMapISOLAMENTO05total +
    pessoasSearchMapISOLAMENTO06total +
    pessoasSearchMapISOLAMENTO07total +
    pessoasSearchMapISOLAMENTO08total +
    pessoasSearchMapISOLAMENTO09total +
    pessoasSearchMapISOLAMENTO10total

  // GALERIA BRAVO 
  const totalSomaB =
    pessoasSearchMapB00total +
    pessoasSearchMapB01total +
    pessoasSearchMapB02total +
    pessoasSearchMapB03total +
    pessoasSearchMapB04total +
    pessoasSearchMapB05total +
    pessoasSearchMapB06total +
    pessoasSearchMapB07total +
    pessoasSearchMapB08total +
    pessoasSearchMapB09total +
    pessoasSearchMapB10total +
    pessoasSearchMapB11total +
    pessoasSearchMapB12total +
    pessoasSearchMapB13total +
    pessoasSearchMapB14total +
    pessoasSearchMapB15total




  // QUADRANTES BRAVO 


  // GALERIA CHARLIE


  const totalSomaC =
    pessoasSearchMapC00total +
    pessoasSearchMapC01total +
    pessoasSearchMapC02total +
    pessoasSearchMapC03total +
    pessoasSearchMapC04total +
    pessoasSearchMapC05total +
    pessoasSearchMapC06total +
    pessoasSearchMapC07total +
    pessoasSearchMapC08total +
    pessoasSearchMapC09total +
    pessoasSearchMapC10total +
    pessoasSearchMapC11total +
    pessoasSearchMapC12total +
    pessoasSearchMapC13total +
    pessoasSearchMapC14total +
    pessoasSearchMapC15total +
    pessoasSearchMapC16total +
    pessoasSearchMapC17total



  // QUADRANTES CHARLIE 






  const totalSomaD =
    pessoasSearchMapD00total +
    pessoasSearchMapD01total +
    pessoasSearchMapD02total +
    pessoasSearchMapD03total +
    pessoasSearchMapD04total +
    pessoasSearchMapD05total +
    pessoasSearchMapD06total +
    pessoasSearchMapD07total +
    pessoasSearchMapD08total +
    pessoasSearchMapD09total +
    pessoasSearchMapD10total +
    pessoasSearchMapD11total +
    pessoasSearchMapD12total +
    pessoasSearchMapD13total +
    pessoasSearchMapD14total +
    pessoasSearchMapD15total +
    pessoasSearchMapD16total +
    pessoasSearchMapD17total


  const totalSomaE =
    pessoasSearchMapE00total +
    pessoasSearchMapE01total +
    pessoasSearchMapE02total +
    pessoasSearchMapE03total +
    pessoasSearchMapE04total +
    pessoasSearchMapE05total +
    pessoasSearchMapE06total +
    pessoasSearchMapE07total +
    pessoasSearchMapE08total +
    pessoasSearchMapE09total +
    pessoasSearchMapE10total +
    pessoasSearchMapE11total +
    pessoasSearchMapE12total +
    pessoasSearchMapE13total +
    pessoasSearchMapE14total +
    pessoasSearchMapE15total +
    pessoasSearchMapE16total +
    pessoasSearchMapE17total





  const totalSomaTR =
    pessoasSearchMapTR00total +
    pessoasSearchMapTR01total +
    pessoasSearchMapTR02total +
    pessoasSearchMapTR03total +
    pessoasSearchMapTR04total



  const totalUp =
    totalSomaA +
    totalSomaB +
    totalSomaC +
    totalSomaD +
    totalSomaE +
    totalSomaTR +
    totalISOLAMENTO


  const totalGeral = totalUp +  pessoasHospitaltotal + pessoasPernoitetotal + pessoasDomiciliartotal




  const paginaCapa = () => {

    setAtivaBotao(6)
    setPagina(6)


  }
  const paginaGaleriaA = () => {
    setAtivaBotao(1)
    setPagina(1)

  }
  const paginaGaleriaB = () => {
    setAtivaBotao(2)
    setPagina(2)

  }
  const paginaGaleriaC = () => {

    setAtivaBotao(3)
    setPagina(3)

  }
  const paginaGaleriaE = () => {

    setAtivaBotao(8)
    setPagina(8)

  }
  const paginaGaleriaF = () => {

    setAtivaBotao(9)
    setPagina(9)

  }
  const paginaGaleriaG = () => {

    setAtivaBotao(10)
    setPagina(10)

  }
  const paginaGaleriaTR = () => {

    setAtivaBotao(11)
    setPagina(11)

  }
  const paginaGaleriaD = () => {

    setAtivaBotao(4)
    setPagina(4)

  }
  const paginaGeral = () => {

    setAtivaBotao(5)
    setPagina(5)

  }

  // A101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA01 = "A01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA01(filteredResults);
      setPessoasSearchMapA01total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA01).toUpperCase())
  }, [galerias])

  // A02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA02 = "A02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA02(filteredResults);
      setPessoasSearchMapA02total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA02).toUpperCase())
  }, [galerias])

  // A03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA03 = "A03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA03(filteredResults);
      setPessoasSearchMapA03total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA03).toUpperCase())
  }, [galerias])

  // A04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA04 = "A04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA04(filteredResults);
      setPessoasSearchMapA04total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA04).toUpperCase())
  }, [galerias])

  // A05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA05 = "A05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA05(filteredResults);
      setPessoasSearchMapA05total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA05).toUpperCase())
  }, [galerias])

  // A06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA06 = "A06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA06(filteredResults);
      setPessoasSearchMapA06total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA06).toUpperCase())
  }, [galerias])

  // A07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA07 = "A07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA07(filteredResults);
      setPessoasSearchMapA07total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA07).toUpperCase())
  }, [galerias])


  // A08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA08 = "A08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA08(filteredResults);
      setPessoasSearchMapA08total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA08).toUpperCase())
  }, [galerias])
  // A09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA09 = "A09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA09(filteredResults);
      setPessoasSearchMapA09total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA09).toUpperCase())
  }, [galerias])

  // A10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA10 = "A10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA10(filteredResults);
      setPessoasSearchMapA10total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA10).toUpperCase())
  }, [galerias])


  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA11 = "A11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA11(filteredResults);
      setPessoasSearchMapA11total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA11).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA12 = "A12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA12(filteredResults);
      setPessoasSearchMapA12total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA12).toUpperCase())
  }, [galerias])


  // B00
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB00 = "B00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB00(filteredResults);
      setPessoasSearchMapB00total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB00).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB01 = "B01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB01(filteredResults);
      setPessoasSearchMapB01total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB01).toUpperCase())
  }, [galerias])

  // B02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB02 = "B02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB02(filteredResults);
      setPessoasSearchMapB02total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB02).toUpperCase())
  }, [galerias])

  // B03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB03 = "B03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB03(filteredResults);
      setPessoasSearchMapB03total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB03).toUpperCase())
  }, [galerias])

  // B04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB04 = "B04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB04(filteredResults);
      setPessoasSearchMapB04total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB04).toUpperCase())
  }, [galerias])
  // B05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB05 = "B05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB05(filteredResults);
      setPessoasSearchMapB05total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB05).toUpperCase())
  }, [galerias])

  // B06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB06 = "B06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB06(filteredResults);
      setPessoasSearchMapB06total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB06).toUpperCase())
  }, [galerias])

  // B07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB07 = "B07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB07(filteredResults);
      setPessoasSearchMapB07total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB07).toUpperCase())
  }, [galerias])


  // B08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB08 = "B08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB08(filteredResults);
      setPessoasSearchMapB08total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB08).toUpperCase())
  }, [galerias])
  // B09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB09 = "B09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB09(filteredResults);
      setPessoasSearchMapB09total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB09).toUpperCase())
  }, [galerias])

  // B10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB10 = "B10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB10(filteredResults);
      setPessoasSearchMapB10total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB10).toUpperCase())
  }, [galerias])
  // B11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB11 = "B11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB11(filteredResults);
      setPessoasSearchMapB11total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB11).toUpperCase())
  }, [galerias])

  // B12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB12 = "B12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB12(filteredResults);
      setPessoasSearchMapB12total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB12).toUpperCase())
  }, [galerias])
  // B12

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB13 = "B13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB13(filteredResults);
      setPessoasSearchMapB13total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB13).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB14 = "B14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB14(filteredResults);
      setPessoasSearchMapB14total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB14).toUpperCase())
  }, [galerias])



  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB15 = "B15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB15(filteredResults);
      setPessoasSearchMapB15total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB15).toUpperCase())
  }, [galerias])



  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC00 = "C00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC00(filteredResults);
      setPessoasSearchMapC00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC00).toUpperCase())
  }, [galerias])
  //C C01


  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC01 = "C01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC01(filteredResults);
      setPessoasSearchMapC01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC01).toUpperCase())
  }, [galerias])

  // C02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC02 = "C02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC02(filteredResults);
      setPessoasSearchMapC02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC02).toUpperCase())
  }, [galerias])

  // C03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC03 = "C03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC03(filteredResults);
      setPessoasSearchMapC03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC03).toUpperCase())
  }, [galerias])

  // C04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC04 = "C04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC04(filteredResults);
      setPessoasSearchMapC04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC04).toUpperCase())
  }, [galerias])
  // C05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC05 = "C05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC05(filteredResults);
      setPessoasSearchMapC05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC05).toUpperCase())
  }, [galerias])

  // C06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC06 = "C06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC06(filteredResults);
      setPessoasSearchMapC06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC06).toUpperCase())
  }, [galerias])

  // C07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC07 = "C07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC07(filteredResults);
      setPessoasSearchMapC07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC07).toUpperCase())
  }, [galerias])


  // C08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC08 = "C08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC08(filteredResults);
      setPessoasSearchMapC08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC08).toUpperCase())
  }, [galerias])
  // C09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC09 = "C09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC09(filteredResults);
      setPessoasSearchMapC09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC10 = "C10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC10(filteredResults);
      setPessoasSearchMapC10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC10).toUpperCase())
  }, [galerias])
  // C11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC11 = "C11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC11(filteredResults);
      setPessoasSearchMapC11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC11).toUpperCase())
  }, [galerias])

  // C12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC12 = "C12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC12(filteredResults);
      setPessoasSearchMapC12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC12).toUpperCase())
  }, [galerias])


  // C13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC13 = "C13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC13(filteredResults);
      setPessoasSearchMapC13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC13).toUpperCase())
  }, [galerias])

  // C14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC14 = "C14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC14(filteredResults);
      setPessoasSearchMapC14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC14).toUpperCase())
  }, [galerias])

  // C15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC15 = "C15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC15(filteredResults);
      setPessoasSearchMapC15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC15).toUpperCase())
  }, [galerias])


  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC16 = "C16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC16(filteredResults);
      setPessoasSearchMapC16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC16).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC17 = "C17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC17(filteredResults);
      setPessoasSearchMapC17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC17).toUpperCase())
  }, [galerias])



  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD00 = "D00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD00(filteredResults);
      setPessoasSearchMapD00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD00).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD01 = "D01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD01(filteredResults);
      setPessoasSearchMapD01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD01).toUpperCase())
  }, [galerias])

  // D02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD02 = "D02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD02(filteredResults);
      setPessoasSearchMapD02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD02).toUpperCase())
  }, [galerias])

  // D03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD03 = "D03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD03(filteredResults);
      setPessoasSearchMapD03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD03).toUpperCase())
  }, [galerias])

  // D04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD04 = "D04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD04(filteredResults);
      setPessoasSearchMapD04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD04).toUpperCase())
  }, [galerias])
  // D05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD05 = "D05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD05(filteredResults);
      setPessoasSearchMapD05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD05).toUpperCase())
  }, [galerias])

  // D06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD06 = "D06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD06(filteredResults);
      setPessoasSearchMapD06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD06).toUpperCase())
  }, [galerias])

  // D07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD07 = "D07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD07(filteredResults);
      setPessoasSearchMapD07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD07).toUpperCase())
  }, [galerias])


  // D08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD08 = "D08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD08(filteredResults);
      setPessoasSearchMapD08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD08).toUpperCase())
  }, [galerias])
  // D09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD09 = "D09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD09(filteredResults);
      setPessoasSearchMapD09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD10 = "D10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD10(filteredResults);
      setPessoasSearchMapD10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD10).toUpperCase())
  }, [galerias])
  // D11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD11 = "D11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD11(filteredResults);
      setPessoasSearchMapD11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD11).toUpperCase())
  }, [galerias])

  // D12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD12 = "D12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD12(filteredResults);
      setPessoasSearchMapD12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD12).toUpperCase())
  }, [galerias])


  // D13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD13 = "D13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD13(filteredResults);
      setPessoasSearchMapD13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD13).toUpperCase())
  }, [galerias])

  // D14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD14 = "D14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD14(filteredResults);
      setPessoasSearchMapD14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD14).toUpperCase())
  }, [galerias])

  // D15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD15 = "D15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD15(filteredResults);
      setPessoasSearchMapD15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD15).toUpperCase())
  }, [galerias])

  // D16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD16 = "D16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD16(filteredResults);
      setPessoasSearchMapD16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD16).toUpperCase())
  }, [galerias])


  // D17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD17 = "D17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD17(filteredResults);
      setPessoasSearchMapD17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD17).toUpperCase())
  }, [galerias])

  //C C01
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE00 = "E00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE00(filteredResults);
      setPessoasSearchMapE00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE00).toUpperCase())
  }, [galerias])
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE01 = "E01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE01(filteredResults);
      setPessoasSearchMapE01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE01).toUpperCase())
  }, [galerias])

  // E02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE02 = "E02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE02(filteredResults);
      setPessoasSearchMapE02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE02).toUpperCase())
  }, [galerias])

  // E03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE03 = "E03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE03(filteredResults);
      setPessoasSearchMapE03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE03).toUpperCase())
  }, [galerias])

  // E04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE04 = "E04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE04(filteredResults);
      setPessoasSearchMapE04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE04).toUpperCase())
  }, [galerias])
  // E05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE05 = "E05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE05(filteredResults);
      setPessoasSearchMapE05total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE05).toUpperCase())
  }, [galerias])

  // E06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE06 = "E06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE06(filteredResults);
      setPessoasSearchMapE06total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE06).toUpperCase())
  }, [galerias])

  // E07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE07 = "E07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE07(filteredResults);
      setPessoasSearchMapE07total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE07).toUpperCase())
  }, [galerias])


  // E08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE08 = "E08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE08(filteredResults);
      setPessoasSearchMapE08total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE08).toUpperCase())
  }, [galerias])
  // E09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE09 = "E09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE09(filteredResults);
      setPessoasSearchMapE09total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE09).toUpperCase())
  }, [galerias])

  // C10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE10 = "E10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE10(filteredResults);
      setPessoasSearchMapE10total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE10).toUpperCase())
  }, [galerias])
  // E11
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE11 = "E11";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE11(filteredResults);
      setPessoasSearchMapE11total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE11).toUpperCase())
  }, [galerias])

  // E12
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE12 = "E12";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE12(filteredResults);
      setPessoasSearchMapE12total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE12).toUpperCase())
  }, [galerias])


  // E13
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE13 = "E13";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE13(filteredResults);
      setPessoasSearchMapE13total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE13).toUpperCase())
  }, [galerias])

  // E14
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE14 = "E14";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE14(filteredResults);
      setPessoasSearchMapE14total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE14).toUpperCase())
  }, [galerias])

  // E15
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE15 = "E15";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE15(filteredResults);
      setPessoasSearchMapE15total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE15).toUpperCase())
  }, [galerias])

  // E16
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE16 = "E16";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE16(filteredResults);
      setPessoasSearchMapE16total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE16).toUpperCase())
  }, [galerias])


  // E17
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaE17 = "E17";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapE17(filteredResults);
      setPessoasSearchMapE17total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaE17).toUpperCase())
  }, [galerias])



  // GALERIA F


  // TR201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR00 = "TR00";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR00(filteredResults);
      setPessoasSearchMapTR00total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR00).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR01 = "TR01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR01(filteredResults);
      setPessoasSearchMapTR01total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR01).toUpperCase())
  }, [galerias])


  // TR02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR02 = "TR02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR02(filteredResults);
      setPessoasSearchMapTR02total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR02).toUpperCase())
  }, [galerias])



  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR03 = "TR03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR03(filteredResults);
      setPessoasSearchMapTR03total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR03).toUpperCase())
  }, [galerias])

  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR04 = "TR04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR04(filteredResults);
      setPessoasSearchMapTR04total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR04).toUpperCase())
  }, [galerias])




  // DOMICILIAR
 

  

  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let hospital = "HOSPITAL";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasHospital(filteredResults);
      setPessoasHospitaltotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((hospital).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let pernoite = "PERNOITE";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasPernoite(filteredResults);
      setPessoasPernoitetotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((pernoite).toUpperCase())
  }, [galerias])


  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO01 = "ISOLAMENTO01";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO01(filteredResults);
      setPessoasSearchMapISOLAMENTO01total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO01).toUpperCase())
  }, [galerias])

  // ISOLAMENTO02
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO02 = "ISOLAMENTO02";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO02(filteredResults);
      setPessoasSearchMapISOLAMENTO02total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO02).toUpperCase())
  }, [galerias])

  // ISOLAMENTO03
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO03 = "ISOLAMENTO03";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO03(filteredResults);
      setPessoasSearchMapISOLAMENTO03total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO03).toUpperCase())
  }, [galerias])

  // ISOLAMENTO04
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO04 = "ISOLAMENTO04";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO04(filteredResults);
      setPessoasSearchMapISOLAMENTO04total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO04).toUpperCase())
  }, [galerias])

  // ISOLAMENTO05
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO05 = "ISOLAMENTO05";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO05(filteredResults);
      setPessoasSearchMapISOLAMENTO05total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO05).toUpperCase())
  }, [galerias])

  // ISOLAMENTO06
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO06 = "ISOLAMENTO06";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO06(filteredResults);
      setPessoasSearchMapISOLAMENTO06total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO06).toUpperCase())
  }, [galerias])

  // ISOLAMENTO07
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO07 = "ISOLAMENTO07";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO07(filteredResults);
      setPessoasSearchMapISOLAMENTO07total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO07).toUpperCase())
  }, [galerias])


  // ISOLAMENTO08
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO08 = "ISOLAMENTO08";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO08(filteredResults);
      setPessoasSearchMapISOLAMENTO08total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO08).toUpperCase())
  }, [galerias])
  // ISOLAMENTO09
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO09 = "ISOLAMENTO09";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO09(filteredResults);
      setPessoasSearchMapISOLAMENTO09total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO09).toUpperCase())
  }, [galerias])

  // A10
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaISOLAMENTO10 = "ISOLAMENTO10";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapISOLAMENTO10(filteredResults);
      setPessoasSearchMapISOLAMENTO10total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaISOLAMENTO10).toUpperCase())
  }, [galerias])



  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let domiciliar_saude = "DOMICILIAR";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasDomiciliar(filteredResults);
      setPessoasDomiciliartotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((domiciliar_saude).toUpperCase())
  }, [galerias])

  return (




    <>
      <div className={styles.listaGalerias}>




        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaCapa}>
          <b style={ativaBotao === 6 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>CAPA</b></b>
        </div>
        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaA}>
          <b style={ativaBotao === 1 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (A)</b></b>
        </div>

        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaB}>
          <b style={ativaBotao === 2 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (B)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaC}>
          <b style={ativaBotao === 3 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (C)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaD}>
          <b style={ativaBotao === 4 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (D)</b></b>
        </div>

        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaE}>
          <b style={ativaBotao === 8 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (E)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaF}>
          <b style={ativaBotao === 9 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>ISOLAMENTO</b></b>
        </div>

        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaTR}>
          <b style={ativaBotao === 11 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>TRIAGEM (TR)</b></b>
        </div>

        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer', marginLeft: 10, }} onClick={exportToExcel}>
          <b style={ativaBotao === 5 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><img src={excel} alt="Logo" height={40} /></b>
        </div>

        {/* Continue com os outros elementos... */}
      </div>

      {(pagina === 6) && <div id='capa'>

        <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CAPA</h1><Button onClick={imprimirConteudo}> <FiPrinter />
        </Button></center></p>
        <center><div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao" style={{
          border: '1px solid black',
          width: '210mm',
          height: '300mm',
          margin: '0 auto',
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: 'center',
          justifyItems: 'center'



        }}>
          <br></br>
          <center>

            <p><h2><img src={logo} alt="Logo" height={48} /><b> POLICIA PENAL | ES</b> </h2></p>
            <p>PENITENCIÁRIA ESTADUAL DE VILA VELHA 3 (PEVV3) <small><b>
              - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small></p>




          </center>
          <Table bordered style={{ width: '40%', fontSize: 12, alignItems: 'center', textAlign: 'center' }}>
            <thead>
              <br></br>
            </thead>
            <tbody>

              <tr>
                <td>GALERIA "A"</td>
                <td>{totalSomaA} </td>
              </tr>
              <tr>
                <td>GALERIA "B"</td>
                <td>{totalSomaB} </td>
              </tr>
              <tr>
                <td>GALERIA "C"</td>
                <td>{totalSomaC} </td>
              </tr>
              <tr>
                <td>GALERIA "D"</td>
                <td>{totalSomaD} </td>
              </tr>
              <tr>
                <td>GALERIA "E"</td>
                <td>{totalSomaE} </td>
              </tr>
              <tr>
                <td>ISOLAMENTO</td>
                <td>{totalISOLAMENTO}</td>
              </tr>


              <tr>
                <td>TRIAGEM</td>
                <td>{totalSomaTR} </td>
              </tr>
              <tr>
                <td> <b>TOTAL NA UP</b></td>
                <td><strong>{totalUp}</strong> </td>
              </tr>


              <tr>
                <td>DOMICILIAR</td>
                <td>{pessoasDomiciliartotal}</td>
              </tr>
             
              <tr>
                <td>HOSPITAL</td>
                <td>{pessoasHospitaltotal}</td>
              </tr>
              <tr>
                <td>PERNOITE</td>
                <td>{pessoasPernoitetotal}</td>
              </tr>
              <tr>
                <td> <b>TOTAL GERAL</b></td>
                <td><strong>{totalGeral}</strong></td>
              </tr>
              <br></br>
              <br></br>

            </tbody>
          </Table>
          <table style={{ width: '90%', borderCollapse: 'collapse', border: '1px solid #ddd', fontSize: '10px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">A</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">B</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">C</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">D</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">E</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">ISO.</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">TR</th>


              </tr>
            </thead>

            <tbody>

              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>-</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>-</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B00</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB00total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C00</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC00total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D00</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD00total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E00</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE00total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.1</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapISOLAMENTO01total }</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR00</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR00total}</td>



              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D01</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE01total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.2</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapISOLAMENTO02total }</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR00</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR00total}</td>



              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D02</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E02</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE02total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.3</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{ pessoasSearchMapISOLAMENTO03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR01</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR01total}</td>

              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D03</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E03</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE03total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.4</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{ pessoasSearchMapISOLAMENTO04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR02</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR02total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D04</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E04</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE04total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.5</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{ pessoasSearchMapISOLAMENTO05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR03</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR03total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D05</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E05</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE05total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.6</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{ pessoasSearchMapISOLAMENTO06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR04</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR04total}</td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D06</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E06</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE06total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.7</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{ pessoasSearchMapISOLAMENTO07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D07</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E07</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE07total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.8</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapISOLAMENTO08total }</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E08</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE08total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.9</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{ pessoasSearchMapISOLAMENTO09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D09</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E09</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE09total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>ISO.10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapISOLAMENTO10total }</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>

              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E10</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE10total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E11</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE11total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>

                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>

              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E12</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE12total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>


              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E13</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE13total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>


              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E14</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE14total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>


              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E15</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE15total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>


              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E16</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>


              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC16total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>E17</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapE17total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>


              </tr>








            </tbody>
          </table>

        </div>

        </center>
      </div>}


      {(pagina === 1) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>GALERIA "A"</h1><Button onClick={imprimirConteudo}> <FiPrinter />
      </Button></center></p>}
      {(pagina === 5) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CONTAGEM GERAL</h1><Button onClick={imprimirConteudo}> <FiPrinter />
      </Button></center></p>}
      {(pagina === 1 || pagina === 5) && <div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao">



        <center> <small style={{ marginTop: -20, }}><b>GALERIA "A" / TOTAL GALERIA ({totalSomaA}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
           
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A01</b></center>
              <Celacontagem cela="A01" celaTotal={pessoasSearchMapA01total} celaMap={(pessoasSearchMapA01 && pessoasSearchMapA01)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A02</b></center>
              <Celacontagem cela="A02" celaTotal={pessoasSearchMapA02total} celaMap={(pessoasSearchMapA02 && pessoasSearchMapA02)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A03</b></center>
              <Celacontagem cela="A03" celaTotal={pessoasSearchMapA03total} celaMap={(pessoasSearchMapA03 && pessoasSearchMapA03)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A04</b></center>
              <Celacontagem cela="A04" celaTotal={pessoasSearchMapA04total} celaMap={(pessoasSearchMapA04 && pessoasSearchMapA04)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A05</b></center>
              <Celacontagem cela="A05" celaTotal={pessoasSearchMapA05total} celaMap={(pessoasSearchMapA05 && pessoasSearchMapA05)} />


            </Col>

 <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A06</b></center>
              <Celacontagem cela="A06" celaTotal={pessoasSearchMapA06total} celaMap={(pessoasSearchMapA06 && pessoasSearchMapA06)} />

            </Col>
          </Row>
          <Row>
           
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A07</b></center>
              <Celacontagem cela="A07" celaTotal={pessoasSearchMapA07total} celaMap={(pessoasSearchMapA07 && pessoasSearchMapA07)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A08</b></center>

              <Celacontagem cela="A08" celaTotal={pessoasSearchMapA08total} celaMap={(pessoasSearchMapA08 && pessoasSearchMapA08)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A09</b></center>

              <Celacontagem cela="A09" celaTotal={pessoasSearchMapA09total} celaMap={(pessoasSearchMapA09 && pessoasSearchMapA09)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A10</b></center>

              <Celacontagem cela="A10" celaTotal={pessoasSearchMapA10total} celaMap={(pessoasSearchMapA10 && pessoasSearchMapA10)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A11</b></center>
              <Celacontagem cela="A11" celaTotal={pessoasSearchMapA11total} celaMap={(pessoasSearchMapA11 && pessoasSearchMapA11)} />
            </Col>

            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A12</b></center>
              <Celacontagem cela="A12" celaTotal={pessoasSearchMapA12total} celaMap={(pessoasSearchMapA12 && pessoasSearchMapA12)} />
            </Col>
          </Row>
          <Row>
           
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
           
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
           
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
           
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
            
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b></b></center>

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b></b></center>

            </Col>
            

          </Row>




        </Container>
        {(pagina === 1) && <div style={{ height: "10mm" }}></div>}

      </div>}
      {(pagina === 5) && <div style={{ height: "10mm" }}></div>}

      {(pagina === 2) && <p className={styles.pulolista}><center><h1 style={pagina === 2 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -80, fontFamily: 'policiapenal' }}>GALERIA "B"</h1><Button onClick={imprimirConteudo}><FiPrinter />
      </Button></center></p>}

      {(pagina === 2 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 2 ? { marginTop: 0 } : { marginTop: -150 }} id="conteudo-para-impressao" name="conteudo-para-impressao">


        <center> <small style={{ marginTop: -10 }}><b>GALERIA "B" / TOTAL GALERIA ({totalSomaB}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B00</b></center>
              <Celacontagem cela="B00" celaTotal={pessoasSearchMapB00total} celaMap={(pessoasSearchMapB00 && pessoasSearchMapB00)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B01</b></center>
              <Celacontagem cela="B01" celaTotal={pessoasSearchMapB01total} celaMap={(pessoasSearchMapB01 && pessoasSearchMapB01)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B02</b></center>
              <Celacontagem cela="B02" celaTotal={pessoasSearchMapB02total} celaMap={(pessoasSearchMapB02 && pessoasSearchMapB02)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B03</b></center>
              <Celacontagem cela="B03" celaTotal={pessoasSearchMapB03total} celaMap={(pessoasSearchMapB03 && pessoasSearchMapB03)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B04</b></center>
              <Celacontagem cela="B04" celaTotal={pessoasSearchMapB04total} celaMap={(pessoasSearchMapB04 && pessoasSearchMapB04)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B05</b></center>
              <Celacontagem cela="B05" celaTotal={pessoasSearchMapB05total} celaMap={(pessoasSearchMapB05 && pessoasSearchMapB05)} />


            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B06</b></center>
              <Celacontagem cela="B06" celaTotal={pessoasSearchMapB06total} celaMap={(pessoasSearchMapB06 && pessoasSearchMapB06)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B07</b></center>
              <Celacontagem cela="B07" celaTotal={pessoasSearchMapB07total} celaMap={(pessoasSearchMapB07 && pessoasSearchMapB07)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B08</b></center>

              <Celacontagem cela="B08" celaTotal={pessoasSearchMapB08total} celaMap={(pessoasSearchMapB08 && pessoasSearchMapB08)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B09</b></center>

              <Celacontagem cela="B09" celaTotal={pessoasSearchMapB09total} celaMap={(pessoasSearchMapB09 && pessoasSearchMapB09)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B10</b></center>

              <Celacontagem cela="B10" celaTotal={pessoasSearchMapB10total} celaMap={(pessoasSearchMapB10 && pessoasSearchMapB10)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B11</b></center>
              <Celacontagem cela="B11" celaTotal={pessoasSearchMapB11total} celaMap={(pessoasSearchMapB11 && pessoasSearchMapB11)} />
            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B12</b></center>
              <Celacontagem cela="B12" celaTotal={pessoasSearchMapB12total} celaMap={(pessoasSearchMapB12 && pessoasSearchMapB12)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>B13</b></center>
              <Celacontagem cela="B13" celaTotal={pessoasSearchMapB13total} celaMap={(pessoasSearchMapB13 && pessoasSearchMapB13)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>B14</b></center>
              <Celacontagem cela="B14" celaTotal={pessoasSearchMapB14total} celaMap={(pessoasSearchMapB14 && pessoasSearchMapB14)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>B15</b></center>
              <Celacontagem cela="B15" celaTotal={pessoasSearchMapB15total} celaMap={(pessoasSearchMapB15 && pessoasSearchMapB15)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b></b></center>

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b></b></center>

            </Col>

          </Row>




        </Container>

      </div>}

      {(pagina === 5) && <div style={{ height: "50mm" }}></div>}
      {(pagina === 3) && <p className={styles.pulolista}><center><h1 style={pagina === 3 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "C"</h1><Button onClick={imprimirConteudo}><FiPrinter />
      </Button></center></p>}

      {(pagina === 3 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 3 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "C" / TOTAL GALERIA ({totalSomaC}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>
        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C00</b></center>
              <Celacontagem cela="C00" celaTotal={pessoasSearchMapC00total} celaMap={(pessoasSearchMapC00 && pessoasSearchMapC00)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C01</b></center>
              <Celacontagem cela="C01" celaTotal={pessoasSearchMapC01total} celaMap={(pessoasSearchMapC01 && pessoasSearchMapC01)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C02</b></center>
              <Celacontagem cela="C02" celaTotal={pessoasSearchMapC02total} celaMap={(pessoasSearchMapC02 && pessoasSearchMapC02)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C03</b></center>
              <Celacontagem cela="C03" celaTotal={pessoasSearchMapC03total} celaMap={(pessoasSearchMapC03 && pessoasSearchMapC03)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C04</b></center>
              <Celacontagem cela="C04" celaTotal={pessoasSearchMapC04total} celaMap={(pessoasSearchMapC04 && pessoasSearchMapC04)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C05</b></center>
              <Celacontagem cela="C05" celaTotal={pessoasSearchMapC05total} celaMap={(pessoasSearchMapC05 && pessoasSearchMapC05)} />


            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C06</b></center>
              <Celacontagem cela="C06" celaTotal={pessoasSearchMapC06total} celaMap={(pessoasSearchMapC06 && pessoasSearchMapC06)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C07</b></center>
              <Celacontagem cela="C07" celaTotal={pessoasSearchMapC07total} celaMap={(pessoasSearchMapC07 && pessoasSearchMapC07)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C08</b></center>

              <Celacontagem cela="C08" celaTotal={pessoasSearchMapC08total} celaMap={(pessoasSearchMapC08 && pessoasSearchMapC08)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C09</b></center>

              <Celacontagem cela="C09" celaTotal={pessoasSearchMapC09total} celaMap={(pessoasSearchMapC09 && pessoasSearchMapC09)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C10</b></center>

              <Celacontagem cela="C10" celaTotal={pessoasSearchMapC10total} celaMap={(pessoasSearchMapC10 && pessoasSearchMapC10)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C11</b></center>
              <Celacontagem cela="C11" celaTotal={pessoasSearchMapC11total} celaMap={(pessoasSearchMapC11 && pessoasSearchMapC11)} />
            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C12</b></center>
              <Celacontagem cela="C12" celaTotal={pessoasSearchMapC12total} celaMap={(pessoasSearchMapC12 && pessoasSearchMapC12)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C13</b></center>
              <Celacontagem cela="C13" celaTotal={pessoasSearchMapC13total} celaMap={(pessoasSearchMapC13 && pessoasSearchMapC13)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C14</b></center>
              <Celacontagem cela="C14" celaTotal={pessoasSearchMapC14total} celaMap={(pessoasSearchMapC14 && pessoasSearchMapC14)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C15</b></center>
              <Celacontagem cela="C15" celaTotal={pessoasSearchMapC15total} celaMap={(pessoasSearchMapC15 && pessoasSearchMapC15)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>C16</b></center>
              <Celacontagem cela="C16" celaTotal={pessoasSearchMapC16total} celaMap={(pessoasSearchMapC16 && pessoasSearchMapC16)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
            <center><b>C17</b></center>
              <Celacontagem cela="C17" celaTotal={pessoasSearchMapC17total} celaMap={(pessoasSearchMapC17 && pessoasSearchMapC17)} />

            </Col>

          </Row>




        </Container>


      </div>}
      {(pagina === 5) && <div style={{ height: "50mm" }}></div>}

      {(pagina === 4) && <p className={styles.pulolista}><center><h1 style={pagina === 4 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "D"</h1><Button onClick={imprimirConteudo}> <FiPrinter />
      </Button></center></p>}

      {(pagina === 4 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 4 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "D" - TOTAL ({totalSomaD}) em {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>


        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D00</b></center>
              <Celacontagem cela="D00" celaTotal={pessoasSearchMapD00total} celaMap={(pessoasSearchMapD00 && pessoasSearchMapD00)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D01</b></center>
              <Celacontagem cela="D01" celaTotal={pessoasSearchMapD01total} celaMap={(pessoasSearchMapD01 && pessoasSearchMapD01)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D02</b></center>
              <Celacontagem cela="D02" celaTotal={pessoasSearchMapD02total} celaMap={(pessoasSearchMapD02 && pessoasSearchMapD02)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D03</b></center>
              <Celacontagem cela="D03" celaTotal={pessoasSearchMapD03total} celaMap={(pessoasSearchMapD03 && pessoasSearchMapD03)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D04</b></center>
              <Celacontagem cela="D04" celaTotal={pessoasSearchMapD04total} celaMap={(pessoasSearchMapD04 && pessoasSearchMapD04)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D05</b></center>
              <Celacontagem cela="D05" celaTotal={pessoasSearchMapD05total} celaMap={(pessoasSearchMapD05 && pessoasSearchMapD05)} />


            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D06</b></center>
              <Celacontagem cela="D06" celaTotal={pessoasSearchMapD06total} celaMap={(pessoasSearchMapD06 && pessoasSearchMapD06)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D07</b></center>
              <Celacontagem cela="D07" celaTotal={pessoasSearchMapD07total} celaMap={(pessoasSearchMapD07 && pessoasSearchMapD07)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D08</b></center>

              <Celacontagem cela="D08" celaTotal={pessoasSearchMapD08total} celaMap={(pessoasSearchMapD08 && pessoasSearchMapD08)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D09</b></center>

              <Celacontagem cela="D09" celaTotal={pessoasSearchMapD09total} celaMap={(pessoasSearchMapD09 && pessoasSearchMapD09)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D10</b></center>

              <Celacontagem cela="D10" celaTotal={pessoasSearchMapD10total} celaMap={(pessoasSearchMapD10 && pessoasSearchMapD10)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D11</b></center>
              <Celacontagem cela="D11" celaTotal={pessoasSearchMapD11total} celaMap={(pessoasSearchMapD11 && pessoasSearchMapD11)} />
            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D12</b></center>
              <Celacontagem cela="D12" celaTotal={pessoasSearchMapD12total} celaMap={(pessoasSearchMapD12 && pessoasSearchMapD12)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D13</b></center>
              <Celacontagem cela="D13" celaTotal={pessoasSearchMapD13total} celaMap={(pessoasSearchMapD13 && pessoasSearchMapD13)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D14</b></center>
              <Celacontagem cela="D14" celaTotal={pessoasSearchMapD14total} celaMap={(pessoasSearchMapD14 && pessoasSearchMapD14)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D15</b></center>
              <Celacontagem cela="D15" celaTotal={pessoasSearchMapD15total} celaMap={(pessoasSearchMapD15 && pessoasSearchMapD15)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>

            </Col>

          </Row>




        </Container>

      </div>}
      {(pagina === 5) && <div style={{ height: "50mm" }}></div>}
      {(pagina === 8) && <p className={styles.pulolista}><center><h1 style={pagina === 8 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "E"</h1><Button onClick={imprimirConteudo}> <FiPrinter />
      </Button></center></p>}

      {(pagina === 8 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 8 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "E" / TOTAL GALERIA ({totalSomaE}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E00</b></center>
              <Celacontagem cela="E00" celaTotal={pessoasSearchMapE00total} celaMap={(pessoasSearchMapE00 && pessoasSearchMapE00)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E01</b></center>
              <Celacontagem cela="E01" celaTotal={pessoasSearchMapE01total} celaMap={(pessoasSearchMapE01 && pessoasSearchMapE01)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E02</b></center>
              <Celacontagem cela="E02" celaTotal={pessoasSearchMapE02total} celaMap={(pessoasSearchMapE02 && pessoasSearchMapE02)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E03</b></center>
              <Celacontagem cela="E03" celaTotal={pessoasSearchMapE03total} celaMap={(pessoasSearchMapE03 && pessoasSearchMapE03)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E04</b></center>
              <Celacontagem cela="E04" celaTotal={pessoasSearchMapE04total} celaMap={(pessoasSearchMapE04 && pessoasSearchMapE04)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E05</b></center>
              <Celacontagem cela="E05" celaTotal={pessoasSearchMapE05total} celaMap={(pessoasSearchMapE05 && pessoasSearchMapE05)} />


            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E06</b></center>
              <Celacontagem cela="E06" celaTotal={pessoasSearchMapE06total} celaMap={(pessoasSearchMapE06 && pessoasSearchMapE06)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E07</b></center>
              <Celacontagem cela="E07" celaTotal={pessoasSearchMapE07total} celaMap={(pessoasSearchMapE07 && pessoasSearchMapE07)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E08</b></center>

              <Celacontagem cela="E08" celaTotal={pessoasSearchMapE08total} celaMap={(pessoasSearchMapE08 && pessoasSearchMapE08)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E09</b></center>

              <Celacontagem cela="E09" celaTotal={pessoasSearchMapE09total} celaMap={(pessoasSearchMapE09 && pessoasSearchMapE09)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E10</b></center>

              <Celacontagem cela="E10" celaTotal={pessoasSearchMapE10total} celaMap={(pessoasSearchMapE10 && pessoasSearchMapE10)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E11</b></center>
              <Celacontagem cela="E11" celaTotal={pessoasSearchMapE11total} celaMap={(pessoasSearchMapE11 && pessoasSearchMapE11)} />
            </Col>


          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E12</b></center>
              <Celacontagem cela="E12" celaTotal={pessoasSearchMapE12total} celaMap={(pessoasSearchMapE12 && pessoasSearchMapE12)} />
            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E13</b></center>
              <Celacontagem cela="E13" celaTotal={pessoasSearchMapE13total} celaMap={(pessoasSearchMapE13 && pessoasSearchMapE13)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E14</b></center>
              <Celacontagem cela="E14" celaTotal={pessoasSearchMapE14total} celaMap={(pessoasSearchMapE14 && pessoasSearchMapE14)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E15</b></center>
              <Celacontagem cela="E15" celaTotal={pessoasSearchMapE15total} celaMap={(pessoasSearchMapE15 && pessoasSearchMapE15)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E16</b></center>
              <Celacontagem cela="E16" celaTotal={pessoasSearchMapE16total} celaMap={(pessoasSearchMapE16 && pessoasSearchMapE16)} />

            </Col>
            <Col style={{ width: '28mm', height: '60mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>E17</b></center>
              <Celacontagem cela="E17" celaTotal={pessoasSearchMapE17total} celaMap={(pessoasSearchMapE17 && pessoasSearchMapE17)} />

            </Col>

          </Row>




        </Container>


      </div>}

      {(pagina === 5) && <div style={{ height: "50mm" }}></div>}
      {(pagina === 9) && <p className={styles.pulolista}><center><h1 style={pagina === 9 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>ISOLAMENTO</h1><Button onClick={imprimirConteudo}> <FiPrinter />
      </Button></center></p>}

      {(pagina === 9 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 9 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>ISOLAMENTO / TOTAL GALERIA ({totalISOLAMENTO}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
        {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
        <Row>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
            <center><b>ISOLAMENTO 01</b></center>
            <Celacontagem cela="ISOLAMENTO01" celaTotal={pessoasSearchMapISOLAMENTO01total} celaMap={(pessoasSearchMapISOLAMENTO01 && pessoasSearchMapISOLAMENTO01)} />
          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 02</b></center>
            <Celacontagem cela="ISOLAMENTO02" celaTotal={pessoasSearchMapISOLAMENTO02total} celaMap={(pessoasSearchMapISOLAMENTO02 && pessoasSearchMapISOLAMENTO02)} />
          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 03</b></center>
            <Celacontagem cela="ISOLAMENTO03" celaTotal={pessoasSearchMapISOLAMENTO03total} celaMap={(pessoasSearchMapISOLAMENTO03 && pessoasSearchMapISOLAMENTO03)} />

          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 04</b></center>
            <Celacontagem cela="ISOLAMENTO04" celaTotal={pessoasSearchMapISOLAMENTO04total} celaMap={(pessoasSearchMapISOLAMENTO04 && pessoasSearchMapISOLAMENTO04)} />

          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
            <center><b>ISOLAMENTO 05</b></center>
            <Celacontagem cela="ISOLAMENTO05" celaTotal={pessoasSearchMapISOLAMENTO05total} celaMap={(pessoasSearchMapISOLAMENTO05 && pessoasSearchMapISOLAMENTO05)} />


          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 06</b></center>
            <Celacontagem cela="ISOLAMENTO06" celaTotal={pessoasSearchMapISOLAMENTO06total} celaMap={(pessoasSearchMapISOLAMENTO06 && pessoasSearchMapISOLAMENTO06)} />

          </Col>

        </Row>
        <Row>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>

          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>


          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>


          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>


          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>
          </Col>
          <Col style={{ width: '28mm', height: '50mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>
          </Col>

        </Row>
        <Row>
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 07</b></center>
            <Celacontagem cela="ISOLAMENTO07" celaTotal={pessoasSearchMapISOLAMENTO07total} celaMap={(pessoasSearchMapISOLAMENTO07 && pessoasSearchMapISOLAMENTO07)} />

          </Col>
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 08</b></center>
            <Celacontagem cela="ISOLAMENTO08" celaTotal={pessoasSearchMapISOLAMENTO08total} celaMap={(pessoasSearchMapISOLAMENTO08 && pessoasSearchMapISOLAMENTO08)} />

          </Col>
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 09</b></center>
            <Celacontagem cela="ISOLAMENTO09" celaTotal={pessoasSearchMapISOLAMENTO09total} celaMap={(pessoasSearchMapISOLAMENTO09 && pessoasSearchMapISOLAMENTO09)} />

          </Col>
          <Col style={{ width: '26mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
          <center><b>ISOLAMENTO 10</b></center>
            <Celacontagem cela="ISOLAMENTO10" celaTotal={pessoasSearchMapISOLAMENTO10total} celaMap={(pessoasSearchMapISOLAMENTO10 && pessoasSearchMapISOLAMENTO10)} />

          </Col>
          <Col style={{ width: '26mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
           
          </Col>
          <Col style={{ width: '26mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderBottom: 'none' }}>
            
          </Col>
        </Row>
        <Row  >
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>

          </Col>
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>

          </Col>
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>

          </Col>
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>

          </Col>
          <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>

</Col>            <Col style={{ width: '28mm', height: '40mm', border: '1px solid black', backgroundColor: 'white', borderTop: 'none' }}>

</Col>
        </Row>



      </Container>


      </div>}



      {(pagina === 5) && <div style={{ height: "50mm" }}></div>}
      {(pagina === 11) && <p className={styles.pulolista}><center><h1 style={pagina === 11 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>TRIAGEM</h1><Button onClick={imprimirConteudo}> <FiPrinter />
      </Button></center></p>}

      {(pagina === 11 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 11 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>TRIAGEM / TOTAL GALERIA ({totalSomaTR}) /  {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
          
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR01</b></center>
              <Celacontagem cela="TR01" celaTotal={pessoasSearchMapTR01total} celaMap={(pessoasSearchMapTR01 && pessoasSearchMapTR01)} />
            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR02</b></center>
              <Celacontagem cela="TR02" celaTotal={pessoasSearchMapTR02total} celaMap={(pessoasSearchMapTR02 && pessoasSearchMapTR02)} />
            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR03</b></center>
              <Celacontagem cela="TR03" celaTotal={pessoasSearchMapTR03total} celaMap={(pessoasSearchMapTR03 && pessoasSearchMapTR03)} />

            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>TR04</b></center>
              <Celacontagem cela="TR04" celaTotal={pessoasSearchMapTR04total} celaMap={(pessoasSearchMapTR04 && pessoasSearchMapTR04)} />

            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>


          </Row>

          <Row>


            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b></b></center>
            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>
            </Col>
            <Col style={{ width: '26mm', height: '90mm', border: '1px solid black', backgroundColor: 'white' }}>

            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}>
              OBSERVAÇÕES:
            </Col>
            <Col style={{ width: '28mm', height: '90mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p> <small>TOTAL "TR" {totalSomaTR} <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></small></p>
            </Col>
          </Row>




        </Container>






      </div>}

    </>

  )
}

export default Contagem